import { gql } from '@apollo/client';
import { ATTACHMENT_FRAGMENT } from '@fragments/attachment';
import { NOTIFICATIONS_FRAGMENT } from '@fragments/notifications';

export const SALESFORCE_CONTACT_FRAGMENT = gql`
  fragment AllSalesforceContactFields on Contact {
    _hcErr
    _hcLastop
    accountid
    activeC
    billingCorrespondenceC
    backUpPocCommunicationOnlyC
    createddate
    email
    emailUniqueForIntegrationC
    firstname
    id
    isdeleted
    lastname
    mainPocC
    monthlyFinancialsNotificationAndDelC
    name
    phone
    specificCommunicationDetailsC
    systemmodstamp
    taxFilingSigneeC
    taxPocC
    title
  }
`;

export const CONTACT_HISTORY_FRAGMENT = gql`
  ${ATTACHMENT_FRAGMENT}
  ${NOTIFICATIONS_FRAGMENT}
  fragment ContactHistoryFields on ContactHistory {
    action
    contactName
    date
    id
    newValue
    oldValue
    user {
      notifications {
        ...NotificationsFields
      }
      id
      email
      name
      photo {
        ...AttachmentFields
      }
    }
  }
`;
