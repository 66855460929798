export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded date */
  ISO8601Date: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** Represents untyped JSON */
  JSON: any;
  Upload: any;
}

export interface Account {
  __typename?: 'Account';
  _hcErr?: Maybe<Scalars['String']>;
  _hcLastop?: Maybe<Scalars['String']>;
  accountManagerC?: Maybe<Scalars['String']>;
  alphaGroupC?: Maybe<Scalars['String']>;
  caCorpNumberC?: Maybe<Scalars['String']>;
  calendarOrFiscalYearEndC?: Maybe<Scalars['String']>;
  createddate?: Maybe<Scalars['ISO8601DateTime']>;
  dbaC?: Maybe<Scalars['String']>;
  deCorpNumberC?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  effectiveTerminationDateC?: Maybe<Scalars['ISO8601Date']>;
  engagementLetterSignedC?: Maybe<Scalars['Boolean']>;
  engagementLetterSignedDateC?: Maybe<Scalars['ISO8601Date']>;
  feinC?: Maybe<Scalars['String']>;
  financialDeliveryDateC?: Maybe<Scalars['String']>;
  fkaC?: Maybe<Scalars['String']>;
  foreignOwnedBankAccountsCheckboxC?: Maybe<Scalars['Boolean']>;
  foreignSubsidiaryOrSh25C?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  incorporationDateC?: Maybe<Scalars['ISO8601Date']>;
  industry?: Maybe<Scalars['String']>;
  isdeleted?: Maybe<Scalars['Boolean']>;
  kruzeCssqbqIdC?: Maybe<Scalars['Float']>;
  lawFirmC?: Maybe<Scalars['String']>;
  legalCompanyNameC?: Maybe<Scalars['String']>;
  lostToCompetitorC?: Maybe<Scalars['String']>;
  mailingAddressC?: Maybe<Scalars['String']>;
  mainBankC?: Maybe<Scalars['String']>;
  mcpHoursC?: Maybe<Scalars['Float']>;
  mcpTransitionDateC?: Maybe<Scalars['ISO8601Date']>;
  monthlyBillPayPriceC?: Maybe<Scalars['Float']>;
  naicsCodeC?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameOfLawyerC?: Maybe<Scalars['String']>;
  natureOfDepartureMcpNewC?: Maybe<Scalars['String']>;
  onboardingCompletionDateC?: Maybe<Scalars['ISO8601Date']>;
  onboardingKickOffDateC?: Maybe<Scalars['ISO8601Date']>;
  onboardingManagerC?: Maybe<Scalars['String']>;
  payrollProviderC?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  photourl?: Maybe<Scalars['String']>;
  physicalAddressC?: Maybe<Scalars['String']>;
  pricingPlanNameEngagementTypeC?: Maybe<Scalars['String']>;
  salesforceAccountHistories: AccountHistoryPaginated;
  salesforceContactHistories: ContactHistoryPaginated;
  sanFranciscoOperationsCheckboxC?: Maybe<Scalars['Boolean']>;
  sfBusinessAccountNumberBanC?: Maybe<Scalars['String']>;
  sfPinC?: Maybe<Scalars['String']>;
  sfid?: Maybe<Scalars['String']>;
  stateTaxpayerIdNumbersC?: Maybe<Scalars['String']>;
  statesRegisteredForSalesTaxC?: Maybe<Scalars['String']>;
  statesRegisteredToDoBusinessInC?: Maybe<Scalars['String']>;
  statesWith250kInRevenueC?: Maybe<Scalars['String']>;
  statesWith250kInRevenueCheckboxC?: Maybe<Scalars['Boolean']>;
  statesWithW2EmployeesC?: Maybe<Scalars['String']>;
  statusC?: Maybe<Scalars['String']>;
  systemmodstamp?: Maybe<Scalars['ISO8601DateTime']>;
  taxFiscalYearEndC?: Maybe<Scalars['String']>;
  taxMattersPartnerC?: Maybe<Scalars['String']>;
}

export interface AccountSalesforceAccountHistoriesArgs {
  changerId?: InputMaybe<Scalars['ID']>;
  fieldName?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per?: InputMaybe<Scalars['Int']>;
}

export interface AccountSalesforceContactHistoriesArgs {
  changerId?: InputMaybe<Scalars['ID']>;
  page?: InputMaybe<Scalars['Int']>;
  per?: InputMaybe<Scalars['Int']>;
}

export interface AccountGroup {
  __typename?: 'AccountGroup';
  accounts: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
}

/** Autogenerated input type of AccountGroupDelete */
export interface AccountGroupDeleteInput {
  accountGroupId?: InputMaybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of AccountGroupDelete. */
export interface AccountGroupDeletePayload {
  __typename?: 'AccountGroupDeletePayload';
  accountGroup?: Maybe<AccountGroup>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
}

export interface AccountGroupInput {
  accounts: Array<Scalars['String']>;
  name: Scalars['String'];
}

/** Autogenerated input type of AccountGroupSave */
export interface AccountGroupSaveInput {
  accountGroupId?: InputMaybe<Scalars['ID']>;
  attributes: AccountGroupInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of AccountGroupSave. */
export interface AccountGroupSavePayload {
  __typename?: 'AccountGroupSavePayload';
  accountGroup?: Maybe<AccountGroup>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
}

export interface AccountHistory {
  __typename?: 'AccountHistory';
  date: Scalars['ISO8601Date'];
  field: Scalars['String'];
  id: Scalars['ID'];
  newValue?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
}

export interface AccountHistoryPaginated {
  __typename?: 'AccountHistoryPaginated';
  currentPage: Scalars['Int'];
  data: Array<AccountHistory>;
  per: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
}

/** Autogenerated input type of AddClientToAnalysisList */
export interface AddClientToAnalysisListInput {
  clientAnalysisId?: InputMaybe<Scalars['ID']>;
  clientId?: InputMaybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of AddClientToAnalysisList. */
export interface AddClientToAnalysisListPayload {
  __typename?: 'AddClientToAnalysisListPayload';
  clientAnalysis?: Maybe<ClientAnalysis>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
}

export interface Answer {
  __typename?: 'Answer';
  attachments: Array<Attachment>;
  body?: Maybe<Scalars['String']>;
  clientPortalUser: ClientPortalUser;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
}

export interface Attachment {
  __typename?: 'Attachment';
  fileName: Scalars['String'];
  id: Scalars['ID'];
  url: Scalars['String'];
}

export enum BillPayDayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export enum BillPayFee {
  /** Fixed */
  Fixed = 'fixed',
  /** Hourly */
  Hourly = 'hourly'
}

export interface BillPayInformation {
  __typename?: 'BillPayInformation';
  billPayStarts?: Maybe<Scalars['ISO8601Date']>;
  billPayTerminated?: Maybe<Scalars['ISO8601Date']>;
  feeType?: Maybe<BillPayFee>;
  id: Scalars['ID'];
  isConnected: Scalars['Boolean'];
  monthlyFee?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  onBehalf?: Maybe<Scalars['Boolean']>;
  paymentDayOfWeek?: Maybe<BillPayDayOfWeek>;
  paymentUser?: Maybe<User>;
  setupDayOfWeek?: Maybe<BillPayDayOfWeek>;
  setupUser?: Maybe<User>;
  sfContactId?: Maybe<Scalars['ID']>;
}

export interface BillPayInformationInput {
  billPayStarts?: InputMaybe<Scalars['ISO8601Date']>;
  billPayTerminated?: InputMaybe<Scalars['ISO8601Date']>;
  feeType?: InputMaybe<BillPayFee>;
  isConnected: Scalars['Boolean'];
  monthlyFee?: InputMaybe<Scalars['Int']>;
  note?: InputMaybe<Scalars['String']>;
  onBehalf?: InputMaybe<Scalars['Boolean']>;
  paymentDayOfWeek?: InputMaybe<BillPayDayOfWeek>;
  paymentUserId?: InputMaybe<Scalars['ID']>;
  setupDayOfWeek?: InputMaybe<BillPayDayOfWeek>;
  setupUserId?: InputMaybe<Scalars['ID']>;
  sfContactId?: InputMaybe<Scalars['ID']>;
}

/** Autogenerated input type of BillPayInformationSave */
export interface BillPayInformationSaveInput {
  attributes: BillPayInformationInput;
  billPayInformationId?: InputMaybe<Scalars['ID']>;
  clientId?: InputMaybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of BillPayInformationSave. */
export interface BillPayInformationSavePayload {
  __typename?: 'BillPayInformationSavePayload';
  billPayInformation?: Maybe<BillPayInformation>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
}

/** A Kruze client */
export interface Client {
  __typename?: 'Client';
  availableDefaultCharts?: Maybe<Array<ClientDefaultChart>>;
  billPayInformation?: Maybe<BillPayInformation>;
  chartTypes?: Maybe<Scalars['JSON']>;
  clientChartsInfo?: Maybe<ClientChartsInfo>;
  clientPlaidItems: Array<ClientPlaidItem>;
  clientPortalQuestions: Array<Question>;
  clientPortalUsers: Array<ClientPortalUser>;
  clientSaltInfos?: Maybe<Array<ClientSaltInfo>>;
  companyUrl?: Maybe<Scalars['String']>;
  consolidations?: Maybe<Scalars['Boolean']>;
  deactivated?: Maybe<Scalars['Boolean']>;
  defaultCharts?: Maybe<Array<ClientDefaultChart>>;
  defaultReviewer?: Maybe<DefaultReviewer>;
  disableVendorReport: Scalars['Boolean'];
  equityManagementPlatform?: Maybe<EquityManagementPlatform>;
  excelPackets: ExcelPacketPaginated;
  excelVendor1099Reviews: ExcelVendor1099ReviewPaginated;
  externalConnections: ExternalConnections;
  feeIncreaseReports: FeeIncreaseReportPaginated;
  financialBundles: Array<FinancialBundle>;
  id: Scalars['ID'];
  kruzeKeeperEnabled: Scalars['Boolean'];
  logo?: Maybe<Attachment>;
  manager?: Maybe<User>;
  managerHistories: Array<ManagerHistory>;
  mcpHours?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  payrollProvider?: Maybe<Scalars['String']>;
  plaidItemsCount?: Maybe<Scalars['Int']>;
  qboStatus: QboStatus;
  questionAndAnswerSheet?: Maybe<Scalars['String']>;
  rAndDManager?: Maybe<User>;
  salesAndUseTaxInfos?: Maybe<Array<SalesAndUseTaxInfo>>;
  salesforceAccount?: Maybe<Account>;
  salesforceContacts: Array<Contact>;
  scheduleAccountMappings: Array<ScheduleAccountMapping>;
  scheduleItems: Array<ScheduleItem>;
  seniorManager?: Maybe<User>;
  serviceRate?: Maybe<Scalars['Int']>;
  serviceRateChanges: ServiceRateChangePaginated;
  shortcuts: Array<Shortcut>;
  stripeSubscribed?: Maybe<Scalars['Boolean']>;
  taxContact?: Maybe<User>;
  taxPapers: TaxPaperPaginated;
  varianceAnalysis?: Maybe<VarianceAnalysis>;
  vendor1099Reviews: Array<Vendor1099Review>;
  ventureCapitalsFirms: Array<VentureCapitalFirm>;
  workItems?: Maybe<Array<ClientWorkItem>>;
}

/** A Kruze client */
export interface ClientExcelPacketsArgs {
  page?: InputMaybe<Scalars['Int']>;
  per?: InputMaybe<Scalars['Int']>;
}

/** A Kruze client */
export interface ClientExcelVendor1099ReviewsArgs {
  page?: InputMaybe<Scalars['Int']>;
  per?: InputMaybe<Scalars['Int']>;
}

/** A Kruze client */
export interface ClientFeeIncreaseReportsArgs {
  page?: InputMaybe<Scalars['Int']>;
  per?: InputMaybe<Scalars['Int']>;
}

/** A Kruze client */
export interface ClientFinancialBundlesArgs {
  status?: InputMaybe<FinancialBundlesFilter>;
}

/** A Kruze client */
export interface ClientManagerHistoriesArgs {
  managerType: ManagerHistoryManager;
}

/** A Kruze client */
export interface ClientScheduleItemsArgs {
  endDate?: InputMaybe<Scalars['ISO8601Date']>;
  scheduleType?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
}

/** A Kruze client */
export interface ClientServiceRateChangesArgs {
  page?: InputMaybe<Scalars['Int']>;
  per?: InputMaybe<Scalars['Int']>;
}

/** A Kruze client */
export interface ClientTaxPapersArgs {
  page?: InputMaybe<Scalars['Int']>;
  per?: InputMaybe<Scalars['Int']>;
}

export interface ClientAnalysis {
  __typename?: 'ClientAnalysis';
  clients: Array<Client>;
  clientsCount: Scalars['Int'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  revenue: ClientAnalysisRevenuePaginated;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
}

export interface ClientAnalysisRevenueArgs {
  page?: InputMaybe<Scalars['Int']>;
  per?: InputMaybe<Scalars['Int']>;
}

/** Autogenerated input type of ClientAnalysisDelete */
export interface ClientAnalysisDeleteInput {
  clientAnalysisId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of ClientAnalysisDelete. */
export interface ClientAnalysisDeletePayload {
  __typename?: 'ClientAnalysisDeletePayload';
  clientAnalysis?: Maybe<ClientAnalysis>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
}

export interface ClientAnalysisInput {
  clientIds: Array<Scalars['ID']>;
  name: Scalars['String'];
}

export interface ClientAnalysisPaginated {
  __typename?: 'ClientAnalysisPaginated';
  currentPage: Scalars['Int'];
  data: Array<ClientAnalysis>;
  per: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
}

export interface ClientAnalysisRevenue {
  __typename?: 'ClientAnalysisRevenue';
  client: Client;
  currentBurnRate: Scalars['Float'];
  currentCashBalance: Scalars['Float'];
  currentMonthlyRevenue: Scalars['Float'];
  monthOfRunaway: Scalars['Int'];
  threeMonthGrowthRate: Scalars['Float'];
  yearlyGrowthRate: Scalars['Float'];
}

export interface ClientAnalysisRevenuePaginated {
  __typename?: 'ClientAnalysisRevenuePaginated';
  currentPage: Scalars['Int'];
  data: Array<ClientAnalysisRevenue>;
  per: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
}

export interface ClientAnalysisRevenuePaginatedDataArgs {
  endDate?: InputMaybe<Scalars['ISO8601Date']>;
}

/** Autogenerated input type of ClientAnalysisSave */
export interface ClientAnalysisSaveInput {
  attributes: ClientAnalysisInput;
  clientAnalysisId?: InputMaybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of ClientAnalysisSave. */
export interface ClientAnalysisSavePayload {
  __typename?: 'ClientAnalysisSavePayload';
  clientAnalysis?: Maybe<ClientAnalysis>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
}

export interface ClientChart {
  __typename?: 'ClientChart';
  client: Client;
  defaultChartId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  notifications?: Maybe<Array<Scalars['String']>>;
  options?: Maybe<ClientChartOptions>;
  selectedCategories?: Maybe<Array<Scalars['String']>>;
  user?: Maybe<User>;
}

export interface ClientChartChartData {
  __typename?: 'ClientChartChartData';
  key?: Maybe<Scalars['String']>;
  pathData?: Maybe<Array<ClientChartSpecificPathData>>;
}

/** Autogenerated input type of ClientChartDelete */
export interface ClientChartDeleteInput {
  clientChartId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of ClientChartDelete. */
export interface ClientChartDeletePayload {
  __typename?: 'ClientChartDeletePayload';
  clientChart?: Maybe<ClientChart>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
}

export interface ClientChartInput {
  dataViewTypes: Scalars['JSON'];
  name: Scalars['String'];
  selectedCategories: Array<Scalars['String']>;
  stacked: Scalars['Boolean'];
}

export interface ClientChartOptions {
  __typename?: 'ClientChartOptions';
  dataViewTypes?: Maybe<Scalars['JSON']>;
  stacked?: Maybe<Scalars['Boolean']>;
}

/** Autogenerated input type of ClientChartSave */
export interface ClientChartSaveInput {
  attributes: ClientChartInput;
  clientChartId?: InputMaybe<Scalars['ID']>;
  clientId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of ClientChartSave. */
export interface ClientChartSavePayload {
  __typename?: 'ClientChartSavePayload';
  clientChart?: Maybe<ClientChart>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
}

export interface ClientChartSpecificPathData {
  __typename?: 'ClientChartSpecificPathData';
  date?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
}

export interface ClientChartsInfo {
  __typename?: 'ClientChartsInfo';
  clientCharts?: Maybe<Array<ClientChart>>;
  clientChartsPdfLink?: Maybe<Scalars['String']>;
}

/** Autogenerated input type of ClientCreate */
export interface ClientCreateInput {
  attributes: ClientInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of ClientCreate. */
export interface ClientCreatePayload {
  __typename?: 'ClientCreatePayload';
  client?: Maybe<Client>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
}

export interface ClientDefaultChart {
  __typename?: 'ClientDefaultChart';
  accounts?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  stacked?: Maybe<Scalars['Boolean']>;
}

/** Autogenerated input type of ClientDefaultChartCreate */
export interface ClientDefaultChartCreateInput {
  clientId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  defaultChartId: Scalars['ID'];
}

/** Autogenerated return type of ClientDefaultChartCreate. */
export interface ClientDefaultChartCreatePayload {
  __typename?: 'ClientDefaultChartCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  success: Scalars['Boolean'];
}

/** Autogenerated input type of ClientDefaultChartDelete */
export interface ClientDefaultChartDeleteInput {
  clientId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  defaultChartId: Scalars['ID'];
}

/** Autogenerated return type of ClientDefaultChartDelete. */
export interface ClientDefaultChartDeletePayload {
  __typename?: 'ClientDefaultChartDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  success?: Maybe<Scalars['Boolean']>;
}

export interface ClientInput {
  companyUrl?: InputMaybe<Scalars['String']>;
  consolidations?: InputMaybe<Scalars['Boolean']>;
  deactivated?: InputMaybe<Scalars['Boolean']>;
  defaultReviewerAttributes?: InputMaybe<DefaultReviewerInput>;
  disableVendorReport?: InputMaybe<Scalars['Boolean']>;
  equityManagementPlatform?: InputMaybe<EquityManagementPlatform>;
  kruzeKeeperEnabled?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<Scalars['Upload']>;
  name: Scalars['String'];
  questionAndAnswerSheet?: InputMaybe<Scalars['String']>;
  taxContactId?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
}

/** Autogenerated input type of ClientManagerUpdate */
export interface ClientManagerUpdateInput {
  clientId?: InputMaybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
}

/** Autogenerated return type of ClientManagerUpdate. */
export interface ClientManagerUpdatePayload {
  __typename?: 'ClientManagerUpdatePayload';
  client?: Maybe<Client>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
}

export interface ClientPaginated {
  __typename?: 'ClientPaginated';
  currentPage: Scalars['Int'];
  data: Array<Client>;
  per: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
}

export interface ClientPlaidItem {
  __typename?: 'ClientPlaidItem';
  accessToken?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  institutionId?: Maybe<Scalars['String']>;
  institutionName?: Maybe<Scalars['String']>;
  needUpdate?: Maybe<Scalars['Boolean']>;
  plaidItemId?: Maybe<Scalars['String']>;
  selectedAccountId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
}

export enum ClientPortalQuestionStatus {
  Completed = 'COMPLETED',
  Draft = 'DRAFT',
  OnClientSide = 'ON_CLIENT_SIDE'
}

export interface ClientPortalUser {
  __typename?: 'ClientPortalUser';
  confirmed: Scalars['Boolean'];
  createdAt: Scalars['ISO8601DateTime'];
  deactivated?: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  isOwner?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  photo?: Maybe<Attachment>;
  position?: Maybe<Scalars['String']>;
  twoFaEnabled: Scalars['Boolean'];
  updatedAt: Scalars['ISO8601DateTime'];
}

/** Autogenerated input type of ClientPortalUserDelete */
export interface ClientPortalUserDeleteInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
}

/** Autogenerated return type of ClientPortalUserDelete. */
export interface ClientPortalUserDeletePayload {
  __typename?: 'ClientPortalUserDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  clientPortalUser?: Maybe<ClientPortalUser>;
  errors?: Maybe<MutationErrors>;
}

export interface ClientPortalUserInput {
  deactivated?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['String'];
  fullName: Scalars['String'];
  isOwner?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['String']>;
}

/** Autogenerated input type of ClientPortalUserSendInvitation */
export interface ClientPortalUserSendInvitationInput {
  clientId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
}

/** Autogenerated return type of ClientPortalUserSendInvitation. */
export interface ClientPortalUserSendInvitationPayload {
  __typename?: 'ClientPortalUserSendInvitationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  success?: Maybe<Scalars['Boolean']>;
}

/** Autogenerated input type of ClientPortalUserUpdate */
export interface ClientPortalUserUpdateInput {
  attributes: ClientPortalUserInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
}

/** Autogenerated return type of ClientPortalUserUpdate. */
export interface ClientPortalUserUpdatePayload {
  __typename?: 'ClientPortalUserUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  clientPortalUser?: Maybe<ClientPortalUser>;
  errors?: Maybe<MutationErrors>;
}

/** Autogenerated input type of ClientQboConnect */
export interface ClientQboConnectInput {
  attributes: ClientQboInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of ClientQboConnect. */
export interface ClientQboConnectPayload {
  __typename?: 'ClientQboConnectPayload';
  client?: Maybe<Client>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
}

export interface ClientQboInput {
  code: Scalars['String'];
  realmId: Scalars['String'];
  redirectUri: Scalars['String'];
}

/** Autogenerated input type of ClientQboUpdate */
export interface ClientQboUpdateInput {
  clientId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of ClientQboUpdate. */
export interface ClientQboUpdatePayload {
  __typename?: 'ClientQboUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  success?: Maybe<Scalars['Boolean']>;
}

/** Autogenerated input type of ClientRAndDManagerUpdate */
export interface ClientRAndDManagerUpdateInput {
  clientId?: InputMaybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
}

/** Autogenerated return type of ClientRAndDManagerUpdate. */
export interface ClientRAndDManagerUpdatePayload {
  __typename?: 'ClientRAndDManagerUpdatePayload';
  client?: Maybe<Client>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
}

export interface ClientReport {
  __typename?: 'ClientReport';
  accessType: ClientReportAccess;
  clientReportFieldIds: Array<Scalars['Int']>;
  clientReportFilters?: Maybe<Array<ClientReportFilter>>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  reportExportUrl: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
}

export enum ClientReportAccess {
  Personal = 'PERSONAL',
  Shared = 'SHARED'
}

/** Autogenerated input type of ClientReportDelete */
export interface ClientReportDeleteInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  clientReportId?: InputMaybe<Scalars['ID']>;
}

/** Autogenerated return type of ClientReportDelete. */
export interface ClientReportDeletePayload {
  __typename?: 'ClientReportDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  clientReport?: Maybe<ClientReport>;
  errors?: Maybe<MutationErrors>;
}

export interface ClientReportField {
  __typename?: 'ClientReportField';
  columnAlias: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  groupName: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  table: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
}

export interface ClientReportFilter {
  __typename?: 'ClientReportFilter';
  clientReportField?: Maybe<ClientReportField>;
  condition?: Maybe<Scalars['String']>;
  conditionType?: Maybe<ClientReportFilterCondition>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
}

export enum ClientReportFilterCondition {
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  DateRange = 'DATE_RANGE',
  NumericRange = 'NUMERIC_RANGE',
  StringArray = 'STRING_ARRAY'
}

export interface ClientReportFilterInput {
  clientReportFieldId: Scalars['ID'];
  condition: Array<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
}

export interface ClientReportInput {
  accessType: ClientReportAccess;
  clientReportFieldIds: Array<Scalars['ID']>;
  filters?: InputMaybe<Array<ClientReportFilterInput>>;
  name: Scalars['String'];
}

export interface ClientReportPaginated {
  __typename?: 'ClientReportPaginated';
  currentPage: Scalars['Int'];
  data: Array<ClientReport>;
  per: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
}

/** Autogenerated input type of ClientReportSave */
export interface ClientReportSaveInput {
  attributes: ClientReportInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  clientReportId?: InputMaybe<Scalars['ID']>;
}

/** Autogenerated return type of ClientReportSave. */
export interface ClientReportSavePayload {
  __typename?: 'ClientReportSavePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  clientReport?: Maybe<ClientReport>;
  errors?: Maybe<MutationErrors>;
}

export enum ClientSalt {
  /** Annual Report */
  AnnualReport = 'annual_report',
  /** Business License */
  BusinessLicense = 'business_license',
  /** Local or City Registration */
  LocalOrCityRegistration = 'local_or_city_registration',
  /** State Payroll Registration */
  StatePayrollRegistration = 'state_payroll_registration',
  /** State Sales Tax Registration */
  StateSalesTaxRegistration = 'state_sales_tax_registration'
}

export interface ClientSaltInfo {
  __typename?: 'ClientSaltInfo';
  client: Client;
  createdAt: Scalars['ISO8601Date'];
  date?: Maybe<Scalars['ISO8601Date']>;
  frequency?: Maybe<ClientSaltInfoFrequency>;
  id: Scalars['ID'];
  licenseOrTaxNumber?: Maybe<Scalars['String']>;
  renewalRequired?: Maybe<Scalars['Boolean']>;
  saltStateInfo?: Maybe<SaltStateInfo>;
  saltType: ClientSalt;
  updatedAt: Scalars['ISO8601Date'];
}

/** Autogenerated input type of ClientSaltInfoDelete */
export interface ClientSaltInfoDeleteInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  clientSaltInfoId: Scalars['ID'];
}

/** Autogenerated return type of ClientSaltInfoDelete. */
export interface ClientSaltInfoDeletePayload {
  __typename?: 'ClientSaltInfoDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  clientSaltInfo?: Maybe<ClientSaltInfo>;
  errors?: Maybe<MutationErrors>;
}

export enum ClientSaltInfoFrequency {
  /** Annually */
  Annually = 'annually',
  /** Monthly */
  Monthly = 'monthly',
  /** Quarterly */
  Quarterly = 'quarterly'
}

export interface ClientSaltInfoInput {
  date?: InputMaybe<Scalars['ISO8601Date']>;
  frequency?: InputMaybe<Scalars['String']>;
  licenseOrTaxNumber?: InputMaybe<Scalars['String']>;
  renewalRequired: Scalars['Boolean'];
  saltStateInfoId: Scalars['String'];
  saltType: Scalars['String'];
}

/** Autogenerated input type of ClientSaltInfoSave */
export interface ClientSaltInfoSaveInput {
  attributes: ClientSaltInfoInput;
  clientId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  clientSaltInfoId?: InputMaybe<Scalars['ID']>;
}

/** Autogenerated return type of ClientSaltInfoSave. */
export interface ClientSaltInfoSavePayload {
  __typename?: 'ClientSaltInfoSavePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  clientSaltInfo?: Maybe<ClientSaltInfo>;
  errors?: Maybe<MutationErrors>;
}

export interface ClientSpecificProcedure {
  __typename?: 'ClientSpecificProcedure';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
}

/** Autogenerated input type of ClientSpecificProcedureDelete */
export interface ClientSpecificProcedureDeleteInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  clientSpecificProcedureId?: InputMaybe<Scalars['ID']>;
}

/** Autogenerated return type of ClientSpecificProcedureDelete. */
export interface ClientSpecificProcedureDeletePayload {
  __typename?: 'ClientSpecificProcedureDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  clientSpecificProcedure?: Maybe<ClientSpecificProcedure>;
  errors?: Maybe<MutationErrors>;
}

export interface ClientSpecificProcedureInput {
  text: Scalars['String'];
  title: Scalars['String'];
}

/** Autogenerated input type of ClientSpecificProcedureSave */
export interface ClientSpecificProcedureSaveInput {
  attributes: ClientSpecificProcedureInput;
  clientId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  clientSpecificProcedureId?: InputMaybe<Scalars['ID']>;
}

/** Autogenerated return type of ClientSpecificProcedureSave. */
export interface ClientSpecificProcedureSavePayload {
  __typename?: 'ClientSpecificProcedureSavePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  clientSpecificProcedure?: Maybe<ClientSpecificProcedure>;
  errors?: Maybe<MutationErrors>;
}

/** Autogenerated input type of ClientTaxManagerUpdate */
export interface ClientTaxManagerUpdateInput {
  clientId?: InputMaybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
}

/** Autogenerated return type of ClientTaxManagerUpdate. */
export interface ClientTaxManagerUpdatePayload {
  __typename?: 'ClientTaxManagerUpdatePayload';
  client?: Maybe<Client>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
}

/** Autogenerated input type of ClientUpdate */
export interface ClientUpdateInput {
  attributes: ClientInput;
  clientId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of ClientUpdate. */
export interface ClientUpdatePayload {
  __typename?: 'ClientUpdatePayload';
  client?: Maybe<Client>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
}

export interface ClientWorkItem {
  __typename?: 'ClientWorkItem';
  assigneeEmailAddress?: Maybe<Scalars['String']>;
  assigneeKey?: Maybe<Scalars['String']>;
  assigneeName?: Maybe<Scalars['String']>;
  clientKey?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  clientType?: Maybe<Scalars['String']>;
  clientUserDefinedIdentifier?: Maybe<Scalars['String']>;
  completedDate?: Maybe<Scalars['ISO8601DateTime']>;
  dueDate?: Maybe<Scalars['ISO8601DateTime']>;
  estimatedBudget?: Maybe<Scalars['String']>;
  primaryStatus?: Maybe<Scalars['String']>;
  relatedClientGroupKey?: Maybe<Scalars['String']>;
  secondaryStatus?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
  title?: Maybe<Scalars['String']>;
  toDoPeriod?: Maybe<Scalars['ISO8601DateTime']>;
  workItemKey?: Maybe<Scalars['String']>;
  workScheduleKey?: Maybe<Scalars['String']>;
  workStatus?: Maybe<Scalars['String']>;
  workTemplateKey?: Maybe<Scalars['String']>;
  workTemplateTile?: Maybe<Scalars['String']>;
  workType?: Maybe<Scalars['String']>;
}

export enum ClientsFilter {
  /** Active clients */
  Active = 'ACTIVE',
  /** All clients */
  All = 'ALL',
  /** Deactivated clients */
  Deactivated = 'DEACTIVATED',
  /** Clients that belong to current user */
  MyClients = 'MY_CLIENTS',
  /** Clients with outdated QBO connection */
  QboOutdated = 'QBO_OUTDATED',
  /** Clients without MCP budget */
  RateNotSet = 'RATE_NOT_SET'
}

export interface ClientsInformation {
  __typename?: 'ClientsInformation';
  billPayNotSetCount: Scalars['Int'];
  mcpBudgetNotSetCount: Scalars['Int'];
  myClientsCount: Scalars['Int'];
  qboOutdatedCount: Scalars['Int'];
}

export enum CommentSide {
  Client = 'CLIENT',
  Internal = 'INTERNAL'
}

export interface Contact {
  __typename?: 'Contact';
  _hcErr?: Maybe<Scalars['String']>;
  _hcLastop?: Maybe<Scalars['String']>;
  accountid?: Maybe<Scalars['String']>;
  activeC?: Maybe<Scalars['Boolean']>;
  backUpPocCommunicationOnlyC?: Maybe<Scalars['Boolean']>;
  billingCorrespondenceC?: Maybe<Scalars['Boolean']>;
  createddate?: Maybe<Scalars['ISO8601DateTime']>;
  email?: Maybe<Scalars['String']>;
  emailUniqueForIntegrationC?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isdeleted?: Maybe<Scalars['Boolean']>;
  lastname?: Maybe<Scalars['String']>;
  mainPocC?: Maybe<Scalars['Boolean']>;
  monthlyFinancialsNotificationAndDelC?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  specificCommunicationDetailsC?: Maybe<Scalars['String']>;
  systemmodstamp?: Maybe<Scalars['ISO8601DateTime']>;
  taxFilingSigneeC?: Maybe<Scalars['Boolean']>;
  taxPocC?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
}

export interface ContactHistory {
  __typename?: 'ContactHistory';
  action?: Maybe<SalesforceContactAction>;
  contact?: Maybe<Contact>;
  contactName?: Maybe<Scalars['String']>;
  date: Scalars['ISO8601Date'];
  id: Scalars['ID'];
  newValue?: Maybe<Scalars['JSON']>;
  oldValue?: Maybe<Scalars['JSON']>;
  user?: Maybe<User>;
}

export interface ContactHistoryPaginated {
  __typename?: 'ContactHistoryPaginated';
  currentPage: Scalars['Int'];
  data: Array<ContactHistory>;
  per: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
}

export interface DefaultReviewer {
  __typename?: 'DefaultReviewer';
  id: Scalars['ID'];
  reviewer1?: Maybe<User>;
  reviewer2?: Maybe<User>;
  reviewer3?: Maybe<User>;
}

export interface DefaultReviewerInput {
  reviewer1Id?: InputMaybe<Scalars['ID']>;
  reviewer2Id?: InputMaybe<Scalars['ID']>;
  reviewer3Id?: InputMaybe<Scalars['ID']>;
}

export interface DocsAndLinks {
  __typename?: 'DocsAndLinks';
  pages: Array<DocsAndLinksPage>;
  section: Scalars['String'];
}

export interface DocsAndLinksPage {
  __typename?: 'DocsAndLinksPage';
  layout: Scalars['String'];
  title: Scalars['String'];
}

export interface EmailList {
  __typename?: 'EmailList';
  createdAt: Scalars['ISO8601DateTime'];
  emails?: Maybe<Array<EmailListItem>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
}

/** Autogenerated input type of EmailListDelete */
export interface EmailListDeleteInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  emailListId: Scalars['ID'];
}

/** Autogenerated return type of EmailListDelete. */
export interface EmailListDeletePayload {
  __typename?: 'EmailListDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  emailList?: Maybe<EmailList>;
  errors?: Maybe<MutationErrors>;
}

export interface EmailListItem {
  __typename?: 'EmailListItem';
  createdAt: Scalars['ISO8601DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
}

/** Autogenerated input type of EmailListItemDelete */
export interface EmailListItemDeleteInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  emailListItemId: Scalars['ID'];
}

/** Autogenerated return type of EmailListItemDelete. */
export interface EmailListItemDeletePayload {
  __typename?: 'EmailListItemDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  emailListItem?: Maybe<EmailListItem>;
  errors?: Maybe<MutationErrors>;
}

/** Autogenerated input type of EmailListItemSave */
export interface EmailListItemSaveInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  emailListId?: InputMaybe<Scalars['ID']>;
  emailListItemId?: InputMaybe<Scalars['ID']>;
}

/** Autogenerated return type of EmailListItemSave. */
export interface EmailListItemSavePayload {
  __typename?: 'EmailListItemSavePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  emailListItem?: Maybe<EmailListItem>;
  errors?: Maybe<MutationErrors>;
}

/** Autogenerated input type of EmailListSave */
export interface EmailListSaveInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  emailListId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
}

/** Autogenerated return type of EmailListSave. */
export interface EmailListSavePayload {
  __typename?: 'EmailListSavePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  emailList?: Maybe<EmailList>;
  errors?: Maybe<MutationErrors>;
}

export interface EntityVersionItem {
  __typename?: 'EntityVersionItem';
  changeset?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['ISO8601DateTime'];
  event: Scalars['String'];
  id: Scalars['ID'];
  itemId: Scalars['ID'];
  itemType: Scalars['String'];
  object?: Maybe<Scalars['String']>;
  objectChanges?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
}

export enum EquityManagementPlatform {
  Adnales = 'ADNALES',
  CaptableIo = 'CAPTABLE_IO',
  CapShare = 'CAP_SHARE',
  Carta = 'CARTA',
  Pulley = 'PULLEY'
}

export interface ExcelPacket {
  __typename?: 'ExcelPacket';
  alphabetOrderEnabled?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601Date'];
  id: Scalars['ID'];
  report?: Maybe<Attachment>;
  startDate: Scalars['ISO8601Date'];
  status: ExcelPacketStatus;
  user: User;
}

export interface ExcelPacketInput {
  alphabetOrderEnabled?: InputMaybe<Scalars['Boolean']>;
  endDate: Scalars['ISO8601Date'];
  startDate: Scalars['ISO8601Date'];
}

export interface ExcelPacketPaginated {
  __typename?: 'ExcelPacketPaginated';
  currentPage: Scalars['Int'];
  data: Array<ExcelPacket>;
  per: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
}

/** Autogenerated input type of ExcelPacketSave */
export interface ExcelPacketSaveInput {
  attributes: ExcelPacketInput;
  clientId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of ExcelPacketSave. */
export interface ExcelPacketSavePayload {
  __typename?: 'ExcelPacketSavePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  excelPacket?: Maybe<ExcelPacket>;
}

export enum ExcelPacketStatus {
  /** Completed */
  Completed = 'completed',
  /** Failed */
  Failed = 'failed',
  /** In progress */
  InProgress = 'in_progress',
  /** Pending */
  Pending = 'pending'
}

export interface ExcelVendor1099Review {
  __typename?: 'ExcelVendor1099Review';
  createdAt: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601Date'];
  id: Scalars['ID'];
  report?: Maybe<Attachment>;
  startDate: Scalars['ISO8601Date'];
  status: ExcelVendor1099ReviewStatus;
  user: User;
}

export interface ExcelVendor1099ReviewPaginated {
  __typename?: 'ExcelVendor1099ReviewPaginated';
  currentPage: Scalars['Int'];
  data: Array<ExcelVendor1099Review>;
  per: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
}

export enum ExcelVendor1099ReviewStatus {
  /** Completed */
  Completed = 'completed',
  /** Failed */
  Failed = 'failed',
  /** In progress */
  InProgress = 'in_progress',
  /** Pending */
  Pending = 'pending'
}

export interface ExternalConnections {
  __typename?: 'ExternalConnections';
  karbon: Scalars['Boolean'];
  qbo: Scalars['Boolean'];
  qboErrors: Array<Scalars['String']>;
  salesforce: Scalars['Boolean'];
  salesforceClientStatus?: Maybe<Scalars['String']>;
  salesforceErrors: Array<Scalars['String']>;
  stripe: Scalars['Boolean'];
}

export interface FeeIncreaseReport {
  __typename?: 'FeeIncreaseReport';
  clientId: Scalars['Int'];
  createdAt: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601Date'];
  id: Scalars['ID'];
  link?: Maybe<Scalars['String']>;
  startDate: Scalars['ISO8601Date'];
  status: FeeIncreaseReportStatuses;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
}

/** Autogenerated input type of FeeIncreaseReportGenerate */
export interface FeeIncreaseReportGenerateInput {
  attributes: FeeIncreaseReportInput;
  clientId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of FeeIncreaseReportGenerate. */
export interface FeeIncreaseReportGeneratePayload {
  __typename?: 'FeeIncreaseReportGeneratePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  feeIncreaseReport?: Maybe<FeeIncreaseReport>;
}

export interface FeeIncreaseReportInput {
  endDate: Scalars['ISO8601Date'];
  startDate: Scalars['ISO8601Date'];
}

export interface FeeIncreaseReportPaginated {
  __typename?: 'FeeIncreaseReportPaginated';
  currentPage: Scalars['Int'];
  data: Array<FeeIncreaseReport>;
  per: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
}

export enum FeeIncreaseReportStatuses {
  /** Completed */
  Completed = 'completed',
  /** Failed */
  Failed = 'failed',
  /** In progress */
  InProgress = 'in_progress',
  /** Pending */
  Pending = 'pending'
}

export interface FinancialBundle {
  __typename?: 'FinancialBundle';
  chartsPdf?: Maybe<Attachment>;
  createdAt: Scalars['ISO8601DateTime'];
  date: Scalars['ISO8601DateTime'];
  financialPacketXls?: Maybe<Attachment>;
  id: Scalars['ID'];
  name: Scalars['String'];
  seen: Scalars['Boolean'];
  status: FinancialBundlesFilter;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
  varianceAnalysisPdf?: Maybe<Attachment>;
}

/** Autogenerated input type of FinancialBundleDelete */
export interface FinancialBundleDeleteInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  financialBundleId: Scalars['ID'];
}

/** Autogenerated return type of FinancialBundleDelete. */
export interface FinancialBundleDeletePayload {
  __typename?: 'FinancialBundleDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  financialBundle?: Maybe<FinancialBundle>;
}

export interface FinancialBundleInput {
  chartsPdf?: InputMaybe<Scalars['Upload']>;
  date: Scalars['ISO8601DateTime'];
  financialPacketXls?: InputMaybe<Scalars['Upload']>;
  name: Scalars['String'];
  varianceAnalysisPdf?: InputMaybe<Scalars['Upload']>;
}

/** Autogenerated input type of FinancialBundlePublish */
export interface FinancialBundlePublishInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  financialBundleId?: InputMaybe<Scalars['ID']>;
}

/** Autogenerated return type of FinancialBundlePublish. */
export interface FinancialBundlePublishPayload {
  __typename?: 'FinancialBundlePublishPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  financialBundle?: Maybe<FinancialBundle>;
}

/** Autogenerated input type of FinancialBundleSave */
export interface FinancialBundleSaveInput {
  attributes: FinancialBundleInput;
  clientId?: InputMaybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  financialBundleId?: InputMaybe<Scalars['ID']>;
}

/** Autogenerated return type of FinancialBundleSave. */
export interface FinancialBundleSavePayload {
  __typename?: 'FinancialBundleSavePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  financialBundle?: Maybe<FinancialBundle>;
}

export enum FinancialBundlesFilter {
  /** Draft financial bundles */
  Draft = 'DRAFT',
  /** Published financial bundles */
  Published = 'PUBLISHED'
}

export interface FindQuestionsInput {
  askedBy?: InputMaybe<Scalars['ID']>;
  closePeriod?: InputMaybe<Scalars['ISO8601Date']>;
  status?: InputMaybe<ClientPortalQuestionStatus>;
  text?: InputMaybe<Scalars['String']>;
  topic?: InputMaybe<Scalars['String']>;
}

/** Autogenerated input type of FollowUpVendor1099ReviewPocEmail */
export interface FollowUpVendor1099ReviewPocEmailInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  reviewId: Scalars['ID'];
}

/** Autogenerated return type of FollowUpVendor1099ReviewPocEmail. */
export interface FollowUpVendor1099ReviewPocEmailPayload {
  __typename?: 'FollowUpVendor1099ReviewPocEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  success?: Maybe<Scalars['Boolean']>;
}

/** Autogenerated input type of FollowUpVendor1099ReviewVendorEmail */
export interface FollowUpVendor1099ReviewVendorEmailInput {
  attributes: VendorEmailInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  reviewId: Scalars['ID'];
}

/** Autogenerated return type of FollowUpVendor1099ReviewVendorEmail. */
export interface FollowUpVendor1099ReviewVendorEmailPayload {
  __typename?: 'FollowUpVendor1099ReviewVendorEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  success?: Maybe<Scalars['Boolean']>;
}

/** Autogenerated input type of GenerateExcelVendor1099Review */
export interface GenerateExcelVendor1099ReviewInput {
  attributes: Vendor1099ReviewInput;
  clientId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of GenerateExcelVendor1099Review. */
export interface GenerateExcelVendor1099ReviewPayload {
  __typename?: 'GenerateExcelVendor1099ReviewPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  review?: Maybe<ExcelVendor1099Review>;
}

/** Autogenerated input type of GenerateVendor1099Review */
export interface GenerateVendor1099ReviewInput {
  attributes: Vendor1099ReviewInput;
  clientId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of GenerateVendor1099Review. */
export interface GenerateVendor1099ReviewPayload {
  __typename?: 'GenerateVendor1099ReviewPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  review?: Maybe<Vendor1099Review>;
}

export interface History {
  __typename?: 'History';
  action: HistoryAction;
  createdAt: Scalars['ISO8601DateTime'];
  historyableType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
}

export enum HistoryAction {
  /** Approved no issue */
  ApprovedNoIssue = 'APPROVED_NO_ISSUE',
  /** Approved with issues */
  ApprovedWithIssues = 'APPROVED_WITH_ISSUES',
  /** Completed */
  Completed = 'COMPLETED',
  /** Created Note */
  CreatedNote = 'CREATED_NOTE',
  /** Created Question */
  CreatedQuestion = 'CREATED_QUESTION',
  /** Moved to client */
  MovedToClient = 'MOVED_TO_CLIENT',
  /** Moved to review */
  MovedToReview = 'MOVED_TO_REVIEW',
  /** Created Question */
  NAndQCreatedQuestion = 'N_AND_Q_CREATED_QUESTION',
  /** Pushback */
  Pushback = 'PUSHBACK'
}

export interface KeeperQualifier {
  __typename?: 'KeeperQualifier';
  name: Scalars['String'];
  order: Scalars['Int'];
  origin?: Maybe<Scalars['String']>;
  score: Scalars['Int'];
}

export interface KeeperQualifierInput {
  name: Scalars['String'];
  order: Scalars['Int'];
  origin: Scalars['String'];
  score: Scalars['Int'];
}

export interface KeeperRule {
  __typename?: 'KeeperRule';
  account?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  finalScore?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  qualifiers: Array<KeeperQualifier>;
  transactions: Array<Scalars['String']>;
  vendorName: Scalars['String'];
}

export interface KeeperRuleInput {
  account: Scalars['String'];
  enabled: Scalars['Boolean'];
  name: Scalars['String'];
  qualifiers: Array<KeeperQualifierInput>;
}

export interface KeeperRuleSet {
  __typename?: 'KeeperRuleSet';
  availableAccounts: Array<Scalars['String']>;
  client: Client;
  enabledRulesCount?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  generateRulesUrl: Scalars['String'];
  id: Scalars['ID'];
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
  syncProgress: Scalars['Int'];
  synced: Scalars['Boolean'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
}

/** Autogenerated input type of KeeperRuleSetDelete */
export interface KeeperRuleSetDeleteInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  ruleSetId: Scalars['ID'];
}

/** Autogenerated return type of KeeperRuleSetDelete. */
export interface KeeperRuleSetDeletePayload {
  __typename?: 'KeeperRuleSetDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  keeperRuleSet?: Maybe<KeeperRuleSet>;
}

export interface KeeperRuleSetInput {
  accountsToExclude: Array<Scalars['String']>;
  clientId: Scalars['ID'];
  endDate: Scalars['ISO8601Date'];
  startDate: Scalars['ISO8601Date'];
}

export interface KeeperRuleSetPaginated {
  __typename?: 'KeeperRuleSetPaginated';
  currentPage: Scalars['Int'];
  data: Array<KeeperRuleSet>;
  per: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
}

/** Autogenerated input type of KeeperRuleSetSave */
export interface KeeperRuleSetSaveInput {
  attributes: KeeperRuleSetInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  ruleSetId?: InputMaybe<Scalars['ID']>;
}

/** Autogenerated return type of KeeperRuleSetSave. */
export interface KeeperRuleSetSavePayload {
  __typename?: 'KeeperRuleSetSavePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  keeperRuleSet?: Maybe<KeeperRuleSet>;
}

/** Autogenerated input type of KeeperRuleUpdate */
export interface KeeperRuleUpdateInput {
  attributes: KeeperRuleInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  ruleId: Scalars['ID'];
}

/** Autogenerated return type of KeeperRuleUpdate. */
export interface KeeperRuleUpdatePayload {
  __typename?: 'KeeperRuleUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  keeperRule?: Maybe<KeeperRule>;
}

export interface KruzeBlogPostItem {
  __typename?: 'KruzeBlogPostItem';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  publishedAt?: Maybe<Scalars['ISO8601Date']>;
  text: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  url: Scalars['String'];
}

/** Autogenerated input type of KruzeBlogPostItemDelete */
export interface KruzeBlogPostItemDeleteInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  kruzeBlogPostItemId: Scalars['ID'];
}

/** Autogenerated return type of KruzeBlogPostItemDelete. */
export interface KruzeBlogPostItemDeletePayload {
  __typename?: 'KruzeBlogPostItemDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  kruzeBlogPostItem?: Maybe<KruzeBlogPostItem>;
}

export interface KruzeBlogPostItemInput {
  text: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
}

/** Autogenerated input type of KruzeBlogPostItemSave */
export interface KruzeBlogPostItemSaveInput {
  attributes: KruzeBlogPostItemInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  kruzeBlogPostItemId?: InputMaybe<Scalars['ID']>;
}

/** Autogenerated return type of KruzeBlogPostItemSave. */
export interface KruzeBlogPostItemSavePayload {
  __typename?: 'KruzeBlogPostItemSavePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  kruzeBlogPostItem?: Maybe<KruzeBlogPostItem>;
}

export interface KruzeVideoTip {
  __typename?: 'KruzeVideoTip';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['ISO8601Date']>;
  updatedAt: Scalars['ISO8601DateTime'];
  url?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
}

/** Autogenerated input type of KruzeVideoTipDelete */
export interface KruzeVideoTipDeleteInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  kruzeVideoTipId: Scalars['ID'];
}

/** Autogenerated return type of KruzeVideoTipDelete. */
export interface KruzeVideoTipDeletePayload {
  __typename?: 'KruzeVideoTipDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  kruzeVideoTip?: Maybe<KruzeVideoTip>;
}

export interface KruzeVideoTipInput {
  name?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
}

/** Autogenerated input type of KruzeVideoTipSave */
export interface KruzeVideoTipSaveInput {
  attributes: KruzeVideoTipInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of KruzeVideoTipSave. */
export interface KruzeVideoTipSavePayload {
  __typename?: 'KruzeVideoTipSavePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  kruzeVideoTip?: Maybe<KruzeVideoTip>;
}

export interface ManagerHistory {
  __typename?: 'ManagerHistory';
  changedAt: Scalars['ISO8601DateTime'];
  changedBy: User;
  client: Client;
  createdAt: Scalars['ISO8601DateTime'];
  currentManager?: Maybe<User>;
  id: Scalars['ID'];
  managerType: ManagerHistoryManager;
  previousManager?: Maybe<User>;
  updatedAt: Scalars['ISO8601DateTime'];
}

export enum ManagerHistoryManager {
  AccountManager = 'ACCOUNT_MANAGER',
  RAndDManager = 'R_AND_D_MANAGER',
  SeniorManager = 'SENIOR_MANAGER',
  TaxManager = 'TAX_MANAGER'
}

/** Autogenerated input type of MarkAllNotificationsAsDone */
export interface MarkAllNotificationsAsDoneInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
}

/** Autogenerated return type of MarkAllNotificationsAsDone. */
export interface MarkAllNotificationsAsDonePayload {
  __typename?: 'MarkAllNotificationsAsDonePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  success: Scalars['Boolean'];
}

/** Autogenerated input type of MarkNotificationAsDone */
export interface MarkNotificationAsDoneInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  notificationId: Scalars['ID'];
}

/** Autogenerated return type of MarkNotificationAsDone. */
export interface MarkNotificationAsDonePayload {
  __typename?: 'MarkNotificationAsDonePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  notification?: Maybe<Notification>;
}

/** Autogenerated input type of McpBudgetDecline */
export interface McpBudgetDeclineInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
}

/** Autogenerated return type of McpBudgetDecline. */
export interface McpBudgetDeclinePayload {
  __typename?: 'McpBudgetDeclinePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  serviceRateChange?: Maybe<ServiceRateChange>;
}

export interface McpBudgetInput {
  mcpHours: Scalars['Int'];
  notes: Scalars['String'];
  scheduledDate: Scalars['ISO8601Date'];
  serviceRate: Scalars['Float'];
  zendeskLink: Scalars['String'];
}

/** Autogenerated input type of McpBudgetSchedule */
export interface McpBudgetScheduleInput {
  attributes: McpBudgetInput;
  clientId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of McpBudgetSchedule. */
export interface McpBudgetSchedulePayload {
  __typename?: 'McpBudgetSchedulePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  serviceRateChange?: Maybe<ServiceRateChange>;
}

export interface Mutation {
  __typename?: 'Mutation';
  accountGroupDelete?: Maybe<AccountGroupDeletePayload>;
  accountGroupSave?: Maybe<AccountGroupSavePayload>;
  addClientToAnalysisList?: Maybe<AddClientToAnalysisListPayload>;
  billPayInformationSave?: Maybe<BillPayInformationSavePayload>;
  clientAnalysisDelete?: Maybe<ClientAnalysisDeletePayload>;
  clientAnalysisSave?: Maybe<ClientAnalysisSavePayload>;
  clientChartDelete?: Maybe<ClientChartDeletePayload>;
  clientChartSave?: Maybe<ClientChartSavePayload>;
  clientCreate?: Maybe<ClientCreatePayload>;
  clientDefaultChartCreate?: Maybe<ClientDefaultChartCreatePayload>;
  clientDefaultChartDelete?: Maybe<ClientDefaultChartDeletePayload>;
  clientManagerUpdate?: Maybe<ClientManagerUpdatePayload>;
  clientPortalQuestionComplete?: Maybe<QuestionCompletePayload>;
  clientPortalQuestionCreate?: Maybe<QuestionCreatePayload>;
  clientPortalQuestionMoveToClient?: Maybe<QuestionMoveToClientPayload>;
  clientPortalQuestionUpdate?: Maybe<QuestionUpdatePayload>;
  clientPortalUserDelete?: Maybe<ClientPortalUserDeletePayload>;
  clientPortalUserSendInvitation?: Maybe<ClientPortalUserSendInvitationPayload>;
  clientPortalUserUpdate?: Maybe<ClientPortalUserUpdatePayload>;
  clientQboConnect?: Maybe<ClientQboConnectPayload>;
  clientQboUpdate?: Maybe<ClientQboUpdatePayload>;
  clientRAndDManagerUpdate?: Maybe<ClientRAndDManagerUpdatePayload>;
  clientReportDelete?: Maybe<ClientReportDeletePayload>;
  clientReportSave?: Maybe<ClientReportSavePayload>;
  clientSaltInfoDelete?: Maybe<ClientSaltInfoDeletePayload>;
  clientSaltInfoSave?: Maybe<ClientSaltInfoSavePayload>;
  clientSpecificProcedureDelete?: Maybe<ClientSpecificProcedureDeletePayload>;
  clientSpecificProcedureSave?: Maybe<ClientSpecificProcedureSavePayload>;
  clientTaxManagerUpdate?: Maybe<ClientTaxManagerUpdatePayload>;
  clientUpdate?: Maybe<ClientUpdatePayload>;
  createScheduleAccountMapping?: Maybe<ScheduleAccountMappingCreatePayload>;
  createScheduleItem?: Maybe<ScheduleItemCreatePayload>;
  deleteScheduleAccountMapping?: Maybe<ScheduleAccountMappingDeletePayload>;
  deleteScheduleItem?: Maybe<ScheduleItemDeletePayload>;
  emailListDelete?: Maybe<EmailListDeletePayload>;
  emailListItemDelete?: Maybe<EmailListItemDeletePayload>;
  emailListItemSave?: Maybe<EmailListItemSavePayload>;
  emailListSave?: Maybe<EmailListSavePayload>;
  feeIncreaseReportGenerate?: Maybe<FeeIncreaseReportGeneratePayload>;
  financialBundleDelete?: Maybe<FinancialBundleDeletePayload>;
  financialBundlePublish?: Maybe<FinancialBundlePublishPayload>;
  financialBundleSave?: Maybe<FinancialBundleSavePayload>;
  followUpVendor1099ReviewPocEmail?: Maybe<FollowUpVendor1099ReviewPocEmailPayload>;
  followUpVendor1099ReviewVendorEmail?: Maybe<FollowUpVendor1099ReviewVendorEmailPayload>;
  generateExcelVendor1099Review?: Maybe<GenerateExcelVendor1099ReviewPayload>;
  generateVendor1099Review?: Maybe<GenerateVendor1099ReviewPayload>;
  keeperRuleSetDelete?: Maybe<KeeperRuleSetDeletePayload>;
  keeperRuleSetSave?: Maybe<KeeperRuleSetSavePayload>;
  keeperRuleUpdate?: Maybe<KeeperRuleUpdatePayload>;
  kruzeBlogPostItemDelete?: Maybe<KruzeBlogPostItemDeletePayload>;
  kruzeBlogPostItemSave?: Maybe<KruzeBlogPostItemSavePayload>;
  kruzeVideoTipDelete?: Maybe<KruzeVideoTipDeletePayload>;
  kruzeVideoTipSave?: Maybe<KruzeVideoTipSavePayload>;
  markAllNotificationsAsDone?: Maybe<MarkAllNotificationsAsDonePayload>;
  markNotificationAsDone?: Maybe<MarkNotificationAsDonePayload>;
  mcpBudgetDecline?: Maybe<McpBudgetDeclinePayload>;
  mcpBudgetSchedule?: Maybe<McpBudgetSchedulePayload>;
  questionCommentCreate?: Maybe<QuestionCommentCreatePayload>;
  rAndDReportRequest?: Maybe<RAndDReportRequestPayload>;
  requestTaxPaper?: Maybe<TaxPaperRequestPayload>;
  rewardDelete?: Maybe<RewardDeletePayload>;
  rewardPublish?: Maybe<RewardPublishPayload>;
  rewardSave?: Maybe<RewardSavePayload>;
  salesAndUseTaxInfoDelete?: Maybe<SalesAndUseTaxInfoDeletePayload>;
  salesAndUseTaxInfoSave?: Maybe<SalesAndUseTaxInfoSavePayload>;
  salesforceAccountSave?: Maybe<SalesforceAccountSavePayload>;
  salesforceContactActivate?: Maybe<SalesforceContactActivatePayload>;
  salesforceContactDeactivate?: Maybe<SalesforceContactDeactivatePayload>;
  salesforceContactSave?: Maybe<SalesforceContactSavePayload>;
  saveExcelPacket?: Maybe<ExcelPacketSavePayload>;
  sendVendor1099ReviewPocEmail?: Maybe<SendVendor1099ReviewPocEmailPayload>;
  sendVendor1099ReviewVendorEmail?: Maybe<SendVendor1099ReviewVendorEmailPayload>;
  shortcutDelete?: Maybe<ShortcutDeletePayload>;
  shortcutSave?: Maybe<ShortcutSavePayload>;
  subscribeStripe?: Maybe<SubscribeStripePayload>;
  unsubscribeStripe?: Maybe<UnsubscribeStripePayload>;
  updateScheduleAccountMapping?: Maybe<ScheduleAccountMappingUpdatePayload>;
  updateScheduleItem?: Maybe<ScheduleItemUpdatePayload>;
  userSetRoles?: Maybe<UserSetRolesPayload>;
  userUpdate?: Maybe<UserUpdatePayload>;
  varianceAnalysisExportToPdf?: Maybe<VarianceAnalysisExportToPdfPayload>;
  vendorAnalysisDelete?: Maybe<VendorAnalysisDeletePayload>;
  vendorAnalysisSave?: Maybe<VendorAnalysisSavePayload>;
  vendorCreate?: Maybe<VendorCreatePayload>;
  vendorShareReport?: Maybe<VendorShareReportPayload>;
  ventureCapitalFirmDelete?: Maybe<VentureCapitalFirmDeletePayload>;
  ventureCapitalFirmSave?: Maybe<VentureCapitalFirmSavePayload>;
}

export interface MutationAccountGroupDeleteArgs {
  input: AccountGroupDeleteInput;
}

export interface MutationAccountGroupSaveArgs {
  input: AccountGroupSaveInput;
}

export interface MutationAddClientToAnalysisListArgs {
  input: AddClientToAnalysisListInput;
}

export interface MutationBillPayInformationSaveArgs {
  input: BillPayInformationSaveInput;
}

export interface MutationClientAnalysisDeleteArgs {
  input: ClientAnalysisDeleteInput;
}

export interface MutationClientAnalysisSaveArgs {
  input: ClientAnalysisSaveInput;
}

export interface MutationClientChartDeleteArgs {
  input: ClientChartDeleteInput;
}

export interface MutationClientChartSaveArgs {
  input: ClientChartSaveInput;
}

export interface MutationClientCreateArgs {
  input: ClientCreateInput;
}

export interface MutationClientDefaultChartCreateArgs {
  input: ClientDefaultChartCreateInput;
}

export interface MutationClientDefaultChartDeleteArgs {
  input: ClientDefaultChartDeleteInput;
}

export interface MutationClientManagerUpdateArgs {
  input: ClientManagerUpdateInput;
}

export interface MutationClientPortalQuestionCompleteArgs {
  input: QuestionCompleteInput;
}

export interface MutationClientPortalQuestionCreateArgs {
  input: QuestionCreateInput;
}

export interface MutationClientPortalQuestionMoveToClientArgs {
  input: QuestionMoveToClientInput;
}

export interface MutationClientPortalQuestionUpdateArgs {
  input: QuestionUpdateInput;
}

export interface MutationClientPortalUserDeleteArgs {
  input: ClientPortalUserDeleteInput;
}

export interface MutationClientPortalUserSendInvitationArgs {
  input: ClientPortalUserSendInvitationInput;
}

export interface MutationClientPortalUserUpdateArgs {
  input: ClientPortalUserUpdateInput;
}

export interface MutationClientQboConnectArgs {
  input: ClientQboConnectInput;
}

export interface MutationClientQboUpdateArgs {
  input: ClientQboUpdateInput;
}

export interface MutationClientRAndDManagerUpdateArgs {
  input: ClientRAndDManagerUpdateInput;
}

export interface MutationClientReportDeleteArgs {
  input: ClientReportDeleteInput;
}

export interface MutationClientReportSaveArgs {
  input: ClientReportSaveInput;
}

export interface MutationClientSaltInfoDeleteArgs {
  input: ClientSaltInfoDeleteInput;
}

export interface MutationClientSaltInfoSaveArgs {
  input: ClientSaltInfoSaveInput;
}

export interface MutationClientSpecificProcedureDeleteArgs {
  input: ClientSpecificProcedureDeleteInput;
}

export interface MutationClientSpecificProcedureSaveArgs {
  input: ClientSpecificProcedureSaveInput;
}

export interface MutationClientTaxManagerUpdateArgs {
  input: ClientTaxManagerUpdateInput;
}

export interface MutationClientUpdateArgs {
  input: ClientUpdateInput;
}

export interface MutationCreateScheduleAccountMappingArgs {
  input: ScheduleAccountMappingCreateInput;
}

export interface MutationCreateScheduleItemArgs {
  input: ScheduleItemCreateInput;
}

export interface MutationDeleteScheduleAccountMappingArgs {
  input: ScheduleAccountMappingDeleteInput;
}

export interface MutationDeleteScheduleItemArgs {
  input: ScheduleItemDeleteInput;
}

export interface MutationEmailListDeleteArgs {
  input: EmailListDeleteInput;
}

export interface MutationEmailListItemDeleteArgs {
  input: EmailListItemDeleteInput;
}

export interface MutationEmailListItemSaveArgs {
  input: EmailListItemSaveInput;
}

export interface MutationEmailListSaveArgs {
  input: EmailListSaveInput;
}

export interface MutationFeeIncreaseReportGenerateArgs {
  input: FeeIncreaseReportGenerateInput;
}

export interface MutationFinancialBundleDeleteArgs {
  input: FinancialBundleDeleteInput;
}

export interface MutationFinancialBundlePublishArgs {
  input: FinancialBundlePublishInput;
}

export interface MutationFinancialBundleSaveArgs {
  input: FinancialBundleSaveInput;
}

export interface MutationFollowUpVendor1099ReviewPocEmailArgs {
  input: FollowUpVendor1099ReviewPocEmailInput;
}

export interface MutationFollowUpVendor1099ReviewVendorEmailArgs {
  input: FollowUpVendor1099ReviewVendorEmailInput;
}

export interface MutationGenerateExcelVendor1099ReviewArgs {
  input: GenerateExcelVendor1099ReviewInput;
}

export interface MutationGenerateVendor1099ReviewArgs {
  input: GenerateVendor1099ReviewInput;
}

export interface MutationKeeperRuleSetDeleteArgs {
  input: KeeperRuleSetDeleteInput;
}

export interface MutationKeeperRuleSetSaveArgs {
  input: KeeperRuleSetSaveInput;
}

export interface MutationKeeperRuleUpdateArgs {
  input: KeeperRuleUpdateInput;
}

export interface MutationKruzeBlogPostItemDeleteArgs {
  input: KruzeBlogPostItemDeleteInput;
}

export interface MutationKruzeBlogPostItemSaveArgs {
  input: KruzeBlogPostItemSaveInput;
}

export interface MutationKruzeVideoTipDeleteArgs {
  input: KruzeVideoTipDeleteInput;
}

export interface MutationKruzeVideoTipSaveArgs {
  input: KruzeVideoTipSaveInput;
}

export interface MutationMarkAllNotificationsAsDoneArgs {
  input: MarkAllNotificationsAsDoneInput;
}

export interface MutationMarkNotificationAsDoneArgs {
  input: MarkNotificationAsDoneInput;
}

export interface MutationMcpBudgetDeclineArgs {
  input: McpBudgetDeclineInput;
}

export interface MutationMcpBudgetScheduleArgs {
  input: McpBudgetScheduleInput;
}

export interface MutationQuestionCommentCreateArgs {
  input: QuestionCommentCreateInput;
}

export interface MutationRAndDReportRequestArgs {
  input: RAndDReportRequestInput;
}

export interface MutationRequestTaxPaperArgs {
  input: TaxPaperRequestInput;
}

export interface MutationRewardDeleteArgs {
  input: RewardDeleteInput;
}

export interface MutationRewardPublishArgs {
  input: RewardPublishInput;
}

export interface MutationRewardSaveArgs {
  input: RewardSaveInput;
}

export interface MutationSalesAndUseTaxInfoDeleteArgs {
  input: SalesAndUseTaxInfoDeleteInput;
}

export interface MutationSalesAndUseTaxInfoSaveArgs {
  input: SalesAndUseTaxInfoSaveInput;
}

export interface MutationSalesforceAccountSaveArgs {
  input: SalesforceAccountSaveInput;
}

export interface MutationSalesforceContactActivateArgs {
  input: SalesforceContactActivateInput;
}

export interface MutationSalesforceContactDeactivateArgs {
  input: SalesforceContactDeactivateInput;
}

export interface MutationSalesforceContactSaveArgs {
  input: SalesforceContactSaveInput;
}

export interface MutationSaveExcelPacketArgs {
  input: ExcelPacketSaveInput;
}

export interface MutationSendVendor1099ReviewPocEmailArgs {
  input: SendVendor1099ReviewPocEmailInput;
}

export interface MutationSendVendor1099ReviewVendorEmailArgs {
  input: SendVendor1099ReviewVendorEmailInput;
}

export interface MutationShortcutDeleteArgs {
  input: ShortcutDeleteInput;
}

export interface MutationShortcutSaveArgs {
  input: ShortcutSaveInput;
}

export interface MutationSubscribeStripeArgs {
  input: SubscribeStripeInput;
}

export interface MutationUnsubscribeStripeArgs {
  input: UnsubscribeStripeInput;
}

export interface MutationUpdateScheduleAccountMappingArgs {
  input: ScheduleAccountMappingUpdateInput;
}

export interface MutationUpdateScheduleItemArgs {
  input: ScheduleItemUpdateInput;
}

export interface MutationUserSetRolesArgs {
  input: UserSetRolesInput;
}

export interface MutationUserUpdateArgs {
  input: UserUpdateInput;
}

export interface MutationVarianceAnalysisExportToPdfArgs {
  input: VarianceAnalysisExportToPdfInput;
}

export interface MutationVendorAnalysisDeleteArgs {
  input: VendorAnalysisDeleteInput;
}

export interface MutationVendorAnalysisSaveArgs {
  input: VendorAnalysisSaveInput;
}

export interface MutationVendorCreateArgs {
  input: VendorCreateInput;
}

export interface MutationVendorShareReportArgs {
  input: VendorShareReportInput;
}

export interface MutationVentureCapitalFirmDeleteArgs {
  input: VentureCapitalFirmDeleteInput;
}

export interface MutationVentureCapitalFirmSaveArgs {
  input: VentureCapitalFirmSaveInput;
}

export interface MutationErrors {
  __typename?: 'MutationErrors';
  genericError?: Maybe<Scalars['String']>;
  validationErrors: Array<ValidationError>;
}

export interface Notification {
  __typename?: 'Notification';
  client?: Maybe<Client>;
  content: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  isDone: Scalars['Boolean'];
  kind: NotificationKind;
  title: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
}

export enum NotificationKind {
  /** Notification */
  Notification = 'notification',
  /** To do */
  ToDo = 'to_do'
}

export interface NotificationPaginated {
  __typename?: 'NotificationPaginated';
  currentPage: Scalars['Int'];
  data: Array<Notification>;
  per: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
}

export enum NotificationsFilter {
  /** All notifications */
  All = 'ALL',
  /** Done notifications */
  Done = 'DONE',
  /** Undone notifications */
  Undone = 'UNDONE'
}

export enum QboStatus {
  /** QBO connected */
  Connected = 'CONNECTED',
  /** Not QBO clinet */
  NonQbo = 'NON_QBO',
  /** QBO outdated */
  Outdated = 'OUTDATED'
}

export interface QboSyncHistoryItem {
  __typename?: 'QboSyncHistoryItem';
  apAging?: Maybe<Scalars['Boolean']>;
  apAgingError?: Maybe<Scalars['String']>;
  arAging?: Maybe<Scalars['Boolean']>;
  arAgingError?: Maybe<Scalars['String']>;
  balanceSheet?: Maybe<Scalars['Boolean']>;
  balanceSheetError?: Maybe<Scalars['String']>;
  cashFlow?: Maybe<Scalars['Boolean']>;
  cashFlowError?: Maybe<Scalars['String']>;
  clientPreferences?: Maybe<Scalars['Boolean']>;
  clientPreferencesError?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  generalLedger?: Maybe<Scalars['Boolean']>;
  generalLedgerError?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invalidKeys?: Maybe<Scalars['Boolean']>;
  profitAndLoss?: Maybe<Scalars['Boolean']>;
  profitAndLossError?: Maybe<Scalars['String']>;
  transactionList?: Maybe<Scalars['Boolean']>;
  transactionListError?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  vendorExpenses?: Maybe<Scalars['Boolean']>;
  vendorExpensesError?: Maybe<Scalars['String']>;
}

export interface Query {
  __typename?: 'Query';
  /** Find account group by id */
  accountGroup: AccountGroup;
  accountGroups: Array<AccountGroup>;
  /** All email lists */
  allEmailLists: Array<EmailList>;
  allVcFirms: Array<VentureCapitalFirm>;
  /** Find client by id */
  client: Client;
  clientAnalyses: ClientAnalysisPaginated;
  /** Find client analysis by id */
  clientAnalysis: ClientAnalysis;
  /** Get client chart by id */
  clientChart?: Maybe<ClientChart>;
  /** data for client chart */
  clientChartChartData: Array<ClientChartChartData>;
  /** Find question by id */
  clientPortalQuestion: Question;
  /** Filter Client Portal Questions */
  clientPortalQuestionsFilter: Array<Question>;
  /** Client's qbo details */
  clientQboStatuses?: Maybe<Array<QboSyncHistoryItem>>;
  /** Find client report by id */
  clientReport: ClientReport;
  /** List of the report fields */
  clientReportFields: Array<ClientReportField>;
  /** Load filters data by column alias */
  clientReportFilter: Array<Scalars['String']>;
  /** List of the filters for the client report */
  clientReportFilterList: Array<ReportFilters>;
  clientReports: ClientReportPaginated;
  /** Find client specific procedure by id */
  clientSpecificProcedure: ClientSpecificProcedure;
  /** All client specific procedures */
  clientSpecificProcedures: Array<ClientSpecificProcedure>;
  clients: ClientPaginated;
  /** Count of my clients and problematic clients */
  clientsInformation: ClientsInformation;
  /** Search client */
  clientsSearch: Array<Client>;
  /** Current User info */
  currentUser: User;
  /** List of docs and links */
  docsAndLinks: Array<DocsAndLinks>;
  /** Find email list by id */
  emailList: EmailList;
  /** Find financial bundle by id */
  financialBundle: FinancialBundle;
  /** List of the entity version items */
  getEntityVersionItems: Array<EntityVersionItem>;
  /** Versions list of entity */
  getEntityVersions: Array<VersionItem>;
  /** Find suggestion for the Vendor Report fields */
  glSuggestion: Scalars['JSON'];
  keeperRule: KeeperRule;
  keeperRuleCalculateScore: Scalars['Int'];
  keeperRuleSet: KeeperRuleSet;
  keeperRuleSets: KeeperRuleSetPaginated;
  keeperRules: Array<KeeperRule>;
  kruzeBlogPostItem?: Maybe<KruzeBlogPostItem>;
  /** All video tips */
  kruzeBlogPosts: Array<KruzeBlogPostItem>;
  kruzeVideoTip?: Maybe<KruzeVideoTip>;
  /** All video tips */
  kruzeVideoTips: Array<KruzeVideoTip>;
  /** List of the model names */
  modelNames: Array<Scalars['String']>;
  /** Array of topics for notes and questions new form */
  nAndQTopics: Array<Scalars['String']>;
  questionStatistic: QuestionStatistic;
  /** Array of topics for question form */
  questionTopics: Array<Scalars['String']>;
  /** Filter client Questions */
  questionsFilter: Array<Question>;
  rAndDReports: RAndDReportPaginated;
  /** Find reward by id */
  reward: Reward;
  rewards: Array<Reward>;
  /** The list of presets of roles */
  rolePresets: Array<RolePresets>;
  /** List of roles */
  roles: Array<Roles>;
  /** Sales and tax info state */
  salesAndTaxInfoStates: Array<SalesAndTaxInfoState>;
  salesforceContact?: Maybe<Contact>;
  salesforcePicklist: Array<SalesforcePicklistPair>;
  /** List of the salt state info */
  saltStateInfos: Array<SaltStateInfo>;
  serviceRateChange?: Maybe<ServiceRateChange>;
  /** Find user by id */
  user: User;
  users: UserPaginated;
  /** Round for the VC firm */
  vcRound: VcRound;
  /** Round for VC firms */
  vcRounds: Array<Scalars['String']>;
  vendorAnalyses: VendorAnalysisPaginated;
  /** Find vendor analysis by id */
  vendorAnalysis: VendorAnalysis;
  /** Find vendor report by id */
  vendorReport: Vendor;
  vendorReports: VendorReportPaginated;
  vendorsSearch: Array<Scalars['String']>;
  ventureCapitalFirm: VentureCapitalFirm;
}

export interface QueryAccountGroupArgs {
  id: Scalars['ID'];
}

export interface QueryAccountGroupsArgs {
  search?: InputMaybe<Scalars['String']>;
}

export interface QueryAllVcFirmsArgs {
  client?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
}

export interface QueryClientArgs {
  id: Scalars['ID'];
}

export interface QueryClientAnalysesArgs {
  page?: InputMaybe<Scalars['Int']>;
  per?: InputMaybe<Scalars['Int']>;
}

export interface QueryClientAnalysisArgs {
  id: Scalars['ID'];
}

export interface QueryClientChartArgs {
  clientChartId: Scalars['ID'];
  clientId: Scalars['ID'];
}

export interface QueryClientChartChartDataArgs {
  clientId: Scalars['ID'];
  endDate?: InputMaybe<Scalars['ISO8601Date']>;
  pathArray: Array<Scalars['String']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
}

export interface QueryClientPortalQuestionArgs {
  id: Scalars['ID'];
}

export interface QueryClientPortalQuestionsFilterArgs {
  clientId: Scalars['ID'];
  params: FindQuestionsInput;
}

export interface QueryClientQboStatusesArgs {
  id: Scalars['ID'];
}

export interface QueryClientReportArgs {
  id: Scalars['ID'];
}

export interface QueryClientReportFilterArgs {
  columnAlias: Scalars['String'];
}

export interface QueryClientReportsArgs {
  filter?: InputMaybe<ClientReportAccess>;
  page?: InputMaybe<Scalars['Int']>;
  per?: InputMaybe<Scalars['Int']>;
}

export interface QueryClientSpecificProcedureArgs {
  id: Scalars['ID'];
}

export interface QueryClientSpecificProceduresArgs {
  clientId: Scalars['ID'];
}

export interface QueryClientsArgs {
  filter?: InputMaybe<ClientsFilter>;
  page?: InputMaybe<Scalars['Int']>;
  per?: InputMaybe<Scalars['Int']>;
}

export interface QueryClientsSearchArgs {
  qboConnected?: InputMaybe<Scalars['Boolean']>;
  search: Scalars['String'];
}

export interface QueryEmailListArgs {
  id: Scalars['ID'];
}

export interface QueryFinancialBundleArgs {
  id: Scalars['ID'];
}

export interface QueryGetEntityVersionItemsArgs {
  endDate?: InputMaybe<Scalars['ISO8601Date']>;
  itemId: Scalars['ID'];
  model: Scalars['String'];
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
}

export interface QueryGetEntityVersionsArgs {
  endDate?: InputMaybe<Scalars['ISO8601Date']>;
  itemId?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
}

export interface QueryGlSuggestionArgs {
  field: SuggestionFields;
  query: Scalars['String'];
}

export interface QueryKeeperRuleArgs {
  keeperRuleId: Scalars['ID'];
}

export interface QueryKeeperRuleCalculateScoreArgs {
  keeperRuleId: Scalars['ID'];
  qualifier: Scalars['String'];
}

export interface QueryKeeperRuleSetArgs {
  keeperRuleSetId: Scalars['ID'];
}

export interface QueryKeeperRuleSetsArgs {
  page?: InputMaybe<Scalars['Int']>;
  per?: InputMaybe<Scalars['Int']>;
}

export interface QueryKeeperRulesArgs {
  keeperRuleSetId: Scalars['ID'];
}

export interface QueryKruzeBlogPostItemArgs {
  id: Scalars['ID'];
}

export interface QueryKruzeVideoTipArgs {
  id: Scalars['ID'];
}

export interface QueryQuestionStatisticArgs {
  month?: InputMaybe<Scalars['ISO8601DateTime']>;
}

export interface QueryQuestionTopicsArgs {
  id: Scalars['ID'];
}

export interface QueryQuestionsFilterArgs {
  clientId: Scalars['ID'];
  params: FindQuestionsInput;
}

export interface QueryRAndDReportsArgs {
  page?: InputMaybe<Scalars['Int']>;
  per?: InputMaybe<Scalars['Int']>;
}

export interface QueryRewardArgs {
  id: Scalars['ID'];
}

export interface QueryRewardsArgs {
  status?: InputMaybe<RewardsFilter>;
}

export interface QuerySalesforceContactArgs {
  id: Scalars['ID'];
}

export interface QuerySalesforcePicklistArgs {
  multipicklist?: InputMaybe<Array<SfMultipicklistNames>>;
  picklist?: InputMaybe<Array<SfPicklistNames>>;
}

export interface QueryServiceRateChangeArgs {
  id: Scalars['ID'];
}

export interface QueryUserArgs {
  id: Scalars['ID'];
}

export interface QueryUsersArgs {
  page?: InputMaybe<Scalars['Int']>;
  per?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UsersFilter>;
}

export interface QueryVcRoundArgs {
  clientId: Scalars['ID'];
  vcFirmId: Scalars['ID'];
}

export interface QueryVendorAnalysesArgs {
  page?: InputMaybe<Scalars['Int']>;
  per?: InputMaybe<Scalars['Int']>;
}

export interface QueryVendorAnalysisArgs {
  id: Scalars['ID'];
}

export interface QueryVendorReportArgs {
  id: Scalars['ID'];
}

export interface QueryVendorReportsArgs {
  page?: InputMaybe<Scalars['Int']>;
  per?: InputMaybe<Scalars['Int']>;
}

export interface QueryVendorsSearchArgs {
  search: Scalars['String'];
}

export interface QueryVentureCapitalFirmArgs {
  id: Scalars['ID'];
}

export interface Question {
  __typename?: 'Question';
  answers: Array<Answer>;
  attachments: Array<Attachment>;
  closePeriod?: Maybe<Scalars['ISO8601Date']>;
  comments: Array<QuestionComment>;
  completedAt?: Maybe<Scalars['ISO8601DateTime']>;
  content: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  history: Array<History>;
  id: Scalars['ID'];
  movedToClientAt?: Maybe<Scalars['ISO8601DateTime']>;
  seen?: Maybe<Scalars['Boolean']>;
  showFastAnswers: Scalars['Boolean'];
  status: ClientPortalQuestionStatus;
  topic: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
}

export interface QuestionComment {
  __typename?: 'QuestionComment';
  attachments: Array<Attachment>;
  body: Scalars['String'];
  commentableType?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  seen: Scalars['Boolean'];
  side: CommentSide;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
}

/** Autogenerated input type of QuestionCommentCreate */
export interface QuestionCommentCreateInput {
  attributes: QuestionCommentInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  questionId: Scalars['ID'];
}

/** Autogenerated return type of QuestionCommentCreate. */
export interface QuestionCommentCreatePayload {
  __typename?: 'QuestionCommentCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  comment?: Maybe<QuestionComment>;
  errors?: Maybe<MutationErrors>;
}

export interface QuestionCommentInput {
  attachments?: InputMaybe<Array<Scalars['Upload']>>;
  body: Scalars['String'];
}

/** Autogenerated input type of QuestionComplete */
export interface QuestionCompleteInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  questionId: Scalars['ID'];
}

/** Autogenerated return type of QuestionComplete. */
export interface QuestionCompletePayload {
  __typename?: 'QuestionCompletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  question?: Maybe<Question>;
}

/** Autogenerated input type of QuestionCreate */
export interface QuestionCreateInput {
  attributes: QuestionInput;
  clientId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of QuestionCreate. */
export interface QuestionCreatePayload {
  __typename?: 'QuestionCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  question?: Maybe<Question>;
}

export interface QuestionInput {
  attachments?: InputMaybe<Array<Scalars['Upload']>>;
  attachmentsToDelete?: InputMaybe<Array<Scalars['ID']>>;
  closePeriod?: InputMaybe<Scalars['ISO8601DateTime']>;
  content: Scalars['String'];
  topic: Scalars['String'];
}

/** Autogenerated input type of QuestionMoveToClient */
export interface QuestionMoveToClientInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  fastAnswers: Scalars['Boolean'];
  questionId: Scalars['ID'];
}

/** Autogenerated return type of QuestionMoveToClient. */
export interface QuestionMoveToClientPayload {
  __typename?: 'QuestionMoveToClientPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  question?: Maybe<Question>;
}

export interface QuestionStatistic {
  __typename?: 'QuestionStatistic';
  questionsStatisticItem: QuestionStatisticPaginated;
  totalClosedQuestionCount: Scalars['Int'];
  totalOpenedQuestionCount: Scalars['Int'];
  totalProcessingQuestionCount: Scalars['Int'];
}

export interface QuestionStatisticQuestionsStatisticItemArgs {
  client?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  per?: InputMaybe<Scalars['Int']>;
}

export interface QuestionStatisticPaginated {
  __typename?: 'QuestionStatisticPaginated';
  currentPage: Scalars['Int'];
  data: Array<QuestionsStatisticItem>;
  per: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
}

/** Autogenerated input type of QuestionUpdate */
export interface QuestionUpdateInput {
  attributes: QuestionInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  questionId?: InputMaybe<Scalars['ID']>;
}

/** Autogenerated return type of QuestionUpdate. */
export interface QuestionUpdatePayload {
  __typename?: 'QuestionUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  question?: Maybe<Question>;
}

export interface QuestionsStatisticItem {
  __typename?: 'QuestionsStatisticItem';
  client: Client;
  closedQuestionCount: Scalars['Int'];
  openedQuestionCount: Scalars['Int'];
  processingQuestionCount: Scalars['Int'];
}

export interface RAndDReport {
  __typename?: 'RAndDReport';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  status: RAndDReportStatuses;
  user: User;
  year: Scalars['Int'];
  zipFile?: Maybe<Attachment>;
}

export interface RAndDReportInput {
  year: Scalars['Int'];
}

export interface RAndDReportPaginated {
  __typename?: 'RAndDReportPaginated';
  currentPage: Scalars['Int'];
  data: Array<RAndDReport>;
  per: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
}

/** Autogenerated input type of RAndDReportRequest */
export interface RAndDReportRequestInput {
  attributes: RAndDReportInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of RAndDReportRequest. */
export interface RAndDReportRequestPayload {
  __typename?: 'RAndDReportRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  rAndDReport?: Maybe<RAndDReport>;
}

export enum RAndDReportStatuses {
  /** Completed */
  Completed = 'completed',
  /** Failed */
  Failed = 'failed',
  /** In progress */
  InProgress = 'in_progress',
  /** Pending */
  Pending = 'pending'
}

export interface ReportFilters {
  __typename?: 'ReportFilters';
  columnAlias: Scalars['String'];
  conditionType: ClientReportFilterCondition;
  name: Scalars['String'];
}

export interface Reward {
  __typename?: 'Reward';
  createdAt: Scalars['ISO8601DateTime'];
  description: Scalars['String'];
  fullText: Scalars['String'];
  id: Scalars['ID'];
  image?: Maybe<Attachment>;
  link: Scalars['String'];
  name: Scalars['String'];
  smallImage?: Maybe<Attachment>;
  status?: Maybe<RewardsFilter>;
  subtitleOne?: Maybe<Scalars['String']>;
  subtitleTwo?: Maybe<Scalars['String']>;
  topic: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
}

/** Autogenerated input type of RewardDelete */
export interface RewardDeleteInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  rewardId: Scalars['ID'];
}

/** Autogenerated return type of RewardDelete. */
export interface RewardDeletePayload {
  __typename?: 'RewardDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  reward?: Maybe<Reward>;
}

export interface RewardInput {
  description: Scalars['String'];
  fullText: Scalars['String'];
  image?: InputMaybe<Scalars['Upload']>;
  link: Scalars['String'];
  name: Scalars['String'];
  smallImage?: InputMaybe<Scalars['Upload']>;
  subtitleOne: Scalars['String'];
  subtitleTwo: Scalars['String'];
  topic: Scalars['String'];
}

/** Autogenerated input type of RewardPublish */
export interface RewardPublishInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  rewardId?: InputMaybe<Scalars['ID']>;
}

/** Autogenerated return type of RewardPublish. */
export interface RewardPublishPayload {
  __typename?: 'RewardPublishPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  reward?: Maybe<Reward>;
}

/** Autogenerated input type of RewardSave */
export interface RewardSaveInput {
  attributes: RewardInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  rewardId?: InputMaybe<Scalars['ID']>;
}

/** Autogenerated return type of RewardSave. */
export interface RewardSavePayload {
  __typename?: 'RewardSavePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  reward?: Maybe<Reward>;
}

export enum RewardsFilter {
  /** Draft rewards */
  Draft = 'DRAFT',
  /** Published rewards */
  Published = 'PUBLISHED'
}

export interface RolePresets {
  __typename?: 'RolePresets';
  label: Scalars['String'];
  role: Array<Scalars['String']>;
}

export interface Roles {
  __typename?: 'Roles';
  label: Scalars['String'];
  value: Scalars['String'];
}

export interface RolesInput {
  accountDetails?: InputMaybe<Array<Scalars['String']>>;
  administration1099?: InputMaybe<Array<Scalars['String']>>;
  billingAndAdministration?: InputMaybe<Array<Scalars['String']>>;
  budgetAndFees?: InputMaybe<Array<Scalars['String']>>;
  clientAnalysis?: InputMaybe<Array<Scalars['String']>>;
  clientPortal?: InputMaybe<Array<Scalars['String']>>;
  clientReport?: InputMaybe<Array<Scalars['String']>>;
  development?: InputMaybe<Array<Scalars['String']>>;
  excelCss?: InputMaybe<Array<Scalars['String']>>;
  feeIncreaseReport?: InputMaybe<Array<Scalars['String']>>;
  financialModelling?: InputMaybe<Array<Scalars['String']>>;
  financialPackets?: InputMaybe<Array<Scalars['String']>>;
  kruzeKeeper?: InputMaybe<Array<Scalars['String']>>;
  mDAndA?: InputMaybe<Array<Scalars['String']>>;
  notesAndQuestions?: InputMaybe<Array<Scalars['String']>>;
  payrollConnection?: InputMaybe<Array<Scalars['String']>>;
  plaid?: InputMaybe<Array<Scalars['String']>>;
  rAndD?: InputMaybe<Array<Scalars['String']>>;
  reporting?: InputMaybe<Array<Scalars['String']>>;
  salesforceApproval?: InputMaybe<Array<Scalars['String']>>;
  siteAdministration?: InputMaybe<Array<Scalars['String']>>;
  taxInformation?: InputMaybe<Array<Scalars['String']>>;
  userAdministration?: InputMaybe<Array<Scalars['String']>>;
  vendorReporting?: InputMaybe<Array<Scalars['String']>>;
}

export interface SalesAndTaxInfoState {
  __typename?: 'SalesAndTaxInfoState';
  id: Scalars['ID'];
  label: Scalars['String'];
}

export interface SalesAndUseTaxInfo {
  __typename?: 'SalesAndUseTaxInfo';
  client: Client;
  createdAt: Scalars['ISO8601Date'];
  dueDay?: Maybe<Scalars['Int']>;
  filingType: SalesAndUseTaxInfoFiling;
  frequency?: Maybe<SalesAndUseTaxInfoFrequency>;
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  salesAndTaxInfoState: SalesAndTaxInfoState;
  salesDataPulled?: Maybe<SalesAndUseTaxInfoSalesDataPulled>;
  startDate: Scalars['ISO8601Date'];
  taxAccountNumber?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601Date'];
}

/** Autogenerated input type of SalesAndUseTaxInfoDelete */
export interface SalesAndUseTaxInfoDeleteInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  salesAndUseTaxInfoId: Scalars['ID'];
}

/** Autogenerated return type of SalesAndUseTaxInfoDelete. */
export interface SalesAndUseTaxInfoDeletePayload {
  __typename?: 'SalesAndUseTaxInfoDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  salesAndUseTaxInfo?: Maybe<SalesAndUseTaxInfo>;
}

export enum SalesAndUseTaxInfoFiling {
  ExciseTaxReturn = 'EXCISE_TAX_RETURN',
  SalesAndUseTaxReturn = 'SALES_AND_USE_TAX_RETURN'
}

export enum SalesAndUseTaxInfoFrequency {
  Annually = 'ANNUALLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  SemiAnnual = 'SEMI_ANNUAL'
}

export interface SalesAndUseTaxInfoInput {
  dueDay?: InputMaybe<Scalars['Int']>;
  filingType: SalesAndUseTaxInfoFiling;
  frequency: SalesAndUseTaxInfoFrequency;
  notes?: InputMaybe<Scalars['String']>;
  salesAndTaxInfoStateId: Scalars['Int'];
  salesDataPulled: SalesAndUseTaxInfoSalesDataPulled;
  startDate: Scalars['ISO8601Date'];
  taxAccountNumber?: InputMaybe<Scalars['String']>;
}

export enum SalesAndUseTaxInfoSalesDataPulled {
  Avalara = 'AVALARA',
  ClientProvides = 'CLIENT_PROVIDES',
  Other = 'OTHER',
  Quickbooks = 'QUICKBOOKS',
  Shopify = 'SHOPIFY',
  Stripe = 'STRIPE',
  TaxJar = 'TAX_JAR'
}

/** Autogenerated input type of SalesAndUseTaxInfoSave */
export interface SalesAndUseTaxInfoSaveInput {
  attributes: SalesAndUseTaxInfoInput;
  clientId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  salesAndUseTaxInfoId?: InputMaybe<Scalars['ID']>;
}

/** Autogenerated return type of SalesAndUseTaxInfoSave. */
export interface SalesAndUseTaxInfoSavePayload {
  __typename?: 'SalesAndUseTaxInfoSavePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  salesAndUseTaxInfo?: Maybe<SalesAndUseTaxInfo>;
}

export interface SalesforceAccountInput {
  alphaGroupC?: InputMaybe<Array<Scalars['String']>>;
  caCorpNumberC?: InputMaybe<Scalars['String']>;
  calendarOrFiscalYearEndC?: InputMaybe<Scalars['String']>;
  dbaC?: InputMaybe<Scalars['String']>;
  deCorpNumberC?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  effectiveTerminationDateC?: InputMaybe<Scalars['ISO8601Date']>;
  engagementLetterSignedC?: InputMaybe<Scalars['Boolean']>;
  engagementLetterSignedDateC?: InputMaybe<Scalars['ISO8601Date']>;
  feinC?: InputMaybe<Scalars['String']>;
  financialDeliveryDateC?: InputMaybe<Array<Scalars['String']>>;
  fkaC?: InputMaybe<Scalars['String']>;
  foreignOwnedBankAccountsCheckboxC?: InputMaybe<Scalars['Boolean']>;
  foreignSubsidiaryOrSh25C?: InputMaybe<Scalars['Boolean']>;
  incorporationDateC?: InputMaybe<Scalars['ISO8601Date']>;
  industry?: InputMaybe<Array<Scalars['String']>>;
  lawFirmC?: InputMaybe<Scalars['String']>;
  legalCompanyNameC?: InputMaybe<Scalars['String']>;
  lostToCompetitorC?: InputMaybe<Array<Scalars['String']>>;
  mailingAddressC?: InputMaybe<Scalars['String']>;
  mainBankC?: InputMaybe<Scalars['String']>;
  mcpTransitionDateC?: InputMaybe<Scalars['ISO8601Date']>;
  naicsCodeC?: InputMaybe<Scalars['String']>;
  nameOfLawyerC?: InputMaybe<Scalars['String']>;
  natureOfDepartureMcpNewC?: InputMaybe<Array<Scalars['String']>>;
  onboardingCompletionDateC?: InputMaybe<Scalars['ISO8601Date']>;
  onboardingKickOffDateC?: InputMaybe<Scalars['ISO8601Date']>;
  onboardingManagerC?: InputMaybe<Array<Scalars['String']>>;
  payrollProviderC?: InputMaybe<Array<Scalars['String']>>;
  phone?: InputMaybe<Scalars['String']>;
  physicalAddressC?: InputMaybe<Scalars['String']>;
  pricingPlanNameEngagementTypeC?: InputMaybe<Array<Scalars['String']>>;
  sanFranciscoOperationsCheckboxC?: InputMaybe<Scalars['Boolean']>;
  sfBusinessAccountNumberBanC?: InputMaybe<Scalars['String']>;
  sfPinC?: InputMaybe<Scalars['String']>;
  stateTaxpayerIdNumbersC?: InputMaybe<Scalars['String']>;
  statesRegisteredForSalesTaxC?: InputMaybe<Array<Scalars['String']>>;
  statesRegisteredToDoBusinessInC?: InputMaybe<Array<Scalars['String']>>;
  statesWith250kInRevenueC?: InputMaybe<Array<Scalars['String']>>;
  statesWith250kInRevenueCheckboxC?: InputMaybe<Scalars['Boolean']>;
  statesWithW2EmployeesC?: InputMaybe<Array<Scalars['String']>>;
  taxFiscalYearEndC?: InputMaybe<Scalars['String']>;
  taxMattersPartnerC?: InputMaybe<Scalars['String']>;
}

/** Autogenerated input type of SalesforceAccountSave */
export interface SalesforceAccountSaveInput {
  attributes: SalesforceAccountInput;
  clientId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of SalesforceAccountSave. */
export interface SalesforceAccountSavePayload {
  __typename?: 'SalesforceAccountSavePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  salesforceAccount?: Maybe<Account>;
}

export enum SalesforceContactAction {
  /** Contact created */
  Created = 'CREATED',
  /** Contact deactivated */
  Deactivated = 'DEACTIVATED',
  /** Contact updated */
  Updated = 'UPDATED'
}

/** Autogenerated input type of SalesforceContactActivate */
export interface SalesforceContactActivateInput {
  clientId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  salesforceContactId: Scalars['ID'];
}

/** Autogenerated return type of SalesforceContactActivate. */
export interface SalesforceContactActivatePayload {
  __typename?: 'SalesforceContactActivatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  salesforceContact?: Maybe<Contact>;
}

/** Autogenerated input type of SalesforceContactDeactivate */
export interface SalesforceContactDeactivateInput {
  clientId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  salesforceContactId: Scalars['ID'];
}

/** Autogenerated return type of SalesforceContactDeactivate. */
export interface SalesforceContactDeactivatePayload {
  __typename?: 'SalesforceContactDeactivatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  salesforceContact?: Maybe<Contact>;
}

export interface SalesforceContactInput {
  backUpPocCommunicationOnlyC?: InputMaybe<Scalars['Boolean']>;
  billingCorrespondenceC?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['String'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  mainPocC?: InputMaybe<Scalars['Boolean']>;
  monthlyFinancialsNotificationAndDelC?: InputMaybe<Scalars['Boolean']>;
  phone: Scalars['String'];
  specificCommunicationDetailsC?: InputMaybe<Scalars['String']>;
  taxFilingSigneeC?: InputMaybe<Scalars['Boolean']>;
  taxPocC?: InputMaybe<Scalars['Boolean']>;
  title: Scalars['String'];
}

/** Autogenerated input type of SalesforceContactSave */
export interface SalesforceContactSaveInput {
  attributes: SalesforceContactInput;
  clientId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  salesforceContactId?: InputMaybe<Scalars['ID']>;
}

/** Autogenerated return type of SalesforceContactSave. */
export interface SalesforceContactSavePayload {
  __typename?: 'SalesforceContactSavePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  salesforceContact?: Maybe<Contact>;
}

export interface SalesforcePicklistPair {
  __typename?: 'SalesforcePicklistPair';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  multipicklist?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  value?: Maybe<Scalars['String']>;
}

export interface SaltStateInfo {
  __typename?: 'SaltStateInfo';
  id: Scalars['ID'];
  label: Scalars['String'];
}

/** MD&A Schedule Account mapping */
export interface ScheduleAccountMapping {
  __typename?: 'ScheduleAccountMapping';
  accountName: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  scheduleType: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
}

/** Autogenerated input type of ScheduleAccountMappingCreate */
export interface ScheduleAccountMappingCreateInput {
  attributes: ScheduleAccountMappingInput;
  clientId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of ScheduleAccountMappingCreate. */
export interface ScheduleAccountMappingCreatePayload {
  __typename?: 'ScheduleAccountMappingCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  item?: Maybe<ScheduleAccountMapping>;
}

/** Autogenerated input type of ScheduleAccountMappingDelete */
export interface ScheduleAccountMappingDeleteInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  mappingId: Scalars['ID'];
}

/** Autogenerated return type of ScheduleAccountMappingDelete. */
export interface ScheduleAccountMappingDeletePayload {
  __typename?: 'ScheduleAccountMappingDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  item?: Maybe<ScheduleAccountMapping>;
}

export interface ScheduleAccountMappingInput {
  accountName?: InputMaybe<Scalars['String']>;
  scheduleType: Scalars['String'];
}

/** Autogenerated input type of ScheduleAccountMappingUpdate */
export interface ScheduleAccountMappingUpdateInput {
  attributes: ScheduleAccountMappingInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  mappingId: Scalars['ID'];
}

/** Autogenerated return type of ScheduleAccountMappingUpdate. */
export interface ScheduleAccountMappingUpdatePayload {
  __typename?: 'ScheduleAccountMappingUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  item?: Maybe<ScheduleAccountMapping>;
}

/** MD&A Schedule item */
export interface ScheduleItem {
  __typename?: 'ScheduleItem';
  accountName?: Maybe<Scalars['String']>;
  accrualMonth?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  amountPerMonth?: Maybe<Scalars['Float']>;
  annualInterest?: Maybe<Scalars['Float']>;
  assetType?: Maybe<Scalars['String']>;
  className?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  dailyAccruedAmount?: Maybe<Scalars['Float']>;
  dailyAmount?: Maybe<Scalars['Float']>;
  endDate?: Maybe<Scalars['ISO8601Date']>;
  feeAmount?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  invoiceNumber?: Maybe<Scalars['String']>;
  netAmount?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
  numberOfMonths?: Maybe<Scalars['Int']>;
  paidDate?: Maybe<Scalars['ISO8601Date']>;
  quarter?: Maybe<Scalars['String']>;
  salvageValue?: Maybe<Scalars['Float']>;
  scheduleType: Scalars['String'];
  startDate?: Maybe<Scalars['ISO8601Date']>;
  state?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['ISO8601Date']>;
  transactionName?: Maybe<Scalars['String']>;
  transactionType?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  usefulLife?: Maybe<Scalars['String']>;
}

/** Autogenerated input type of ScheduleItemCreate */
export interface ScheduleItemCreateInput {
  attributes: ScheduleItemInput;
  clientId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of ScheduleItemCreate. */
export interface ScheduleItemCreatePayload {
  __typename?: 'ScheduleItemCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  item?: Maybe<ScheduleItem>;
}

/** Autogenerated input type of ScheduleItemDelete */
export interface ScheduleItemDeleteInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  scheduleId: Scalars['ID'];
}

/** Autogenerated return type of ScheduleItemDelete. */
export interface ScheduleItemDeletePayload {
  __typename?: 'ScheduleItemDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  item?: Maybe<ScheduleItem>;
}

export interface ScheduleItemInput {
  accountName?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['Float']>;
  annualInterest?: InputMaybe<Scalars['Float']>;
  assetType?: InputMaybe<Scalars['String']>;
  className?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['ISO8601Date']>;
  feeAmount?: InputMaybe<Scalars['Float']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  paidDate?: InputMaybe<Scalars['ISO8601Date']>;
  salvageValue?: InputMaybe<Scalars['Float']>;
  scheduleType: Scalars['String'];
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
  state?: InputMaybe<Scalars['String']>;
  transactionDate?: InputMaybe<Scalars['ISO8601Date']>;
  transactionName?: InputMaybe<Scalars['String']>;
  transactionType?: InputMaybe<Scalars['String']>;
  usefulLife?: InputMaybe<Scalars['String']>;
}

/** Autogenerated input type of ScheduleItemUpdate */
export interface ScheduleItemUpdateInput {
  attributes: ScheduleItemInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  scheduleId: Scalars['ID'];
}

/** Autogenerated return type of ScheduleItemUpdate. */
export interface ScheduleItemUpdatePayload {
  __typename?: 'ScheduleItemUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  item?: Maybe<ScheduleItem>;
}

/** Autogenerated input type of SendVendor1099ReviewPocEmail */
export interface SendVendor1099ReviewPocEmailInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  reviewId: Scalars['ID'];
}

/** Autogenerated return type of SendVendor1099ReviewPocEmail. */
export interface SendVendor1099ReviewPocEmailPayload {
  __typename?: 'SendVendor1099ReviewPocEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  success?: Maybe<Scalars['Boolean']>;
}

/** Autogenerated input type of SendVendor1099ReviewVendorEmail */
export interface SendVendor1099ReviewVendorEmailInput {
  attributes: VendorEmailInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  reviewId: Scalars['ID'];
}

/** Autogenerated return type of SendVendor1099ReviewVendorEmail. */
export interface SendVendor1099ReviewVendorEmailPayload {
  __typename?: 'SendVendor1099ReviewVendorEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  success?: Maybe<Scalars['Boolean']>;
}

export interface ServiceRateChange {
  __typename?: 'ServiceRateChange';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  newAmount?: Maybe<Scalars['Int']>;
  newHours?: Maybe<Scalars['Float']>;
  notes?: Maybe<Scalars['String']>;
  oldAmount?: Maybe<Scalars['Int']>;
  oldHours?: Maybe<Scalars['Float']>;
  scheduledDate?: Maybe<Scalars['ISO8601Date']>;
  status: ServiceRateChangeStatuses;
  statusChangedAt?: Maybe<Scalars['ISO8601DateTime']>;
  user: User;
  zendeskLink?: Maybe<Scalars['String']>;
}

export interface ServiceRateChangePaginated {
  __typename?: 'ServiceRateChangePaginated';
  currentPage: Scalars['Int'];
  data: Array<ServiceRateChange>;
  per: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
}

export enum ServiceRateChangeStatuses {
  Declined = 'DECLINED',
  Error = 'ERROR',
  Planned = 'PLANNED',
  Processed = 'PROCESSED'
}

export enum SfMultipicklistNames {
  /** States registered for sales tax */
  StatesRegisteredForSalesTaxC = 'STATES_REGISTERED_FOR_SALES_TAX__C',
  /** States Registered To Do Business list */
  StatesRegisteredToDoBusinessIn = 'STATES_REGISTERED_TO_DO_BUSINESS_IN',
  /** 250k States list */
  StatesWith_250KInRevenueC = 'STATES_WITH_250K_IN_REVENUE__C',
  /** States with 2 employees list */
  StatesWithW_2EmployeesC = 'STATES_WITH_W_2_EMPLOYEES__C'
}

export enum SfPicklistNames {
  /** Account manager */
  AccountManager = 'ACCOUNT_MANAGER',
  /** Alpha Group */
  AlphaGroup = 'ALPHA_GROUP',
  /** Financial Delivery Date */
  FinancialDeliveryDate = 'FINANCIAL_DELIVERY_DATE',
  /** Industry */
  Industry = 'INDUSTRY',
  /** Lost to Competitor */
  LostToCompetitor = 'LOST_TO_COMPETITOR',
  /** Nature of Departure */
  NatureOfDeparture = 'NATURE_OF_DEPARTURE',
  /** Onboarding manager */
  OnboardingManager = 'ONBOARDING_MANAGER',
  /** Payroll provider */
  PayrollProvider = 'PAYROLL_PROVIDER',
  /** Pricing Plan/Engagement type */
  PricingPlanNameEngagementType = 'PRICING_PLAN_NAME_ENGAGEMENT_TYPE'
}

export interface ShareVendorReportInput {
  email: Scalars['String'];
  vendorReportId: Scalars['ID'];
}

export interface Shortcut {
  __typename?: 'Shortcut';
  clientId?: Maybe<Scalars['Int']>;
  color?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  icon?: Maybe<ShortcutIcons>;
  id: Scalars['ID'];
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
}

/** Autogenerated input type of ShortcutDelete */
export interface ShortcutDeleteInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  shortcutId?: InputMaybe<Scalars['ID']>;
}

/** Autogenerated return type of ShortcutDelete. */
export interface ShortcutDeletePayload {
  __typename?: 'ShortcutDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  shortcut?: Maybe<Shortcut>;
}

export enum ShortcutIcons {
  Bill = 'BILL',
  Box = 'BOX',
  Csp = 'CSP',
  GoogleDocs = 'GOOGLE_DOCS',
  GoogleSheets = 'GOOGLE_SHEETS',
  Mavenlink = 'MAVENLINK',
  Quickbooks = 'QUICKBOOKS',
  Zendesk = 'ZENDESK'
}

export interface ShortcutInput {
  color?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<ShortcutIcons>;
  link: Scalars['String'];
  name: Scalars['String'];
}

/** Autogenerated input type of ShortcutSave */
export interface ShortcutSaveInput {
  attributes: ShortcutInput;
  clientId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  shortcutId?: InputMaybe<Scalars['ID']>;
}

/** Autogenerated return type of ShortcutSave. */
export interface ShortcutSavePayload {
  __typename?: 'ShortcutSavePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  shortcut?: Maybe<Shortcut>;
}

export interface StripeInput {
  stripeId: Scalars['ID'];
}

/** Autogenerated input type of SubscribeStripe */
export interface SubscribeStripeInput {
  attributes: StripeInput;
  clientId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of SubscribeStripe. */
export interface SubscribeStripePayload {
  __typename?: 'SubscribeStripePayload';
  client?: Maybe<Client>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
}

export interface Subscription {
  __typename?: 'Subscription';
  excelPacketStatus: ExcelPacket;
  excelVendor1099ReviewStatus: ExcelVendor1099Review;
  feeIncreaseReportStatus: FeeIncreaseReport;
  keeperRuleSetStatus: KeeperRuleSet;
  rAndDReportStatus: RAndDReport;
  taxPaperStatus: TaxPaper;
  vendor1099ReviewStatus: Vendor1099Review;
  vendorReportStatus: Vendor;
}

export enum SuggestionFields {
  /** Account words only suggestion */
  AccountWordsOnly = 'ACCOUNT_WORDS_ONLY',
  /** Client name suggestion */
  ClientNameSuggestion = 'CLIENT_NAME_SUGGESTION',
  /** Description words only suggestion */
  DescriptionWordsOnly = 'DESCRIPTION_WORDS_ONLY',
  /** Transaction type suggestion */
  TransactionSuggestion = 'TRANSACTION_SUGGESTION',
  /** Vendor suggestion */
  VendorSuggestion = 'VENDOR_SUGGESTION',
  /** Vendor words only suggestion */
  VendorWordsOnly = 'VENDOR_WORDS_ONLY'
}

export interface TaxPaper {
  __typename?: 'TaxPaper';
  createdAt: Scalars['ISO8601DateTime'];
  endDate: Scalars['ISO8601Date'];
  id: Scalars['ID'];
  link: Scalars['String'];
  startDate: Scalars['ISO8601Date'];
  status?: Maybe<TaxPaperStatuses>;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
}

export interface TaxPaperInput {
  endDate: Scalars['ISO8601Date'];
  startDate: Scalars['ISO8601Date'];
}

export interface TaxPaperPaginated {
  __typename?: 'TaxPaperPaginated';
  currentPage: Scalars['Int'];
  data: Array<TaxPaper>;
  per: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
}

/** Autogenerated input type of TaxPaperRequest */
export interface TaxPaperRequestInput {
  attributes: TaxPaperInput;
  clientId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of TaxPaperRequest. */
export interface TaxPaperRequestPayload {
  __typename?: 'TaxPaperRequestPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  taxPaper?: Maybe<TaxPaper>;
}

export enum TaxPaperStatuses {
  /** Complete */
  Complete = 'complete',
  /** Failed */
  Failed = 'failed',
  /** Processing */
  Processing = 'processing'
}

/** Autogenerated input type of UnsubscribeStripe */
export interface UnsubscribeStripeInput {
  clientId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of UnsubscribeStripe. */
export interface UnsubscribeStripePayload {
  __typename?: 'UnsubscribeStripePayload';
  client?: Maybe<Client>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
}

/** A Kruze Control user */
export interface User {
  __typename?: 'User';
  deactivatedAt?: Maybe<Scalars['ISO8601Date']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  notifications: NotificationPaginated;
  photo?: Maybe<Attachment>;
  role?: Maybe<Scalars['Int']>;
  roles?: Maybe<Scalars['JSON']>;
}

/** A Kruze Control user */
export interface UserNotificationsArgs {
  clientId?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<NotificationsFilter>;
  page?: InputMaybe<Scalars['Int']>;
  per?: InputMaybe<Scalars['Int']>;
}

export interface UserInput {
  deactivatedAt?: InputMaybe<Scalars['ISO8601Date']>;
  name?: InputMaybe<Scalars['String']>;
  photo?: InputMaybe<Scalars['Upload']>;
}

export interface UserPaginated {
  __typename?: 'UserPaginated';
  currentPage: Scalars['Int'];
  data: Array<User>;
  per: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
}

/** Autogenerated input type of UserSetRoles */
export interface UserSetRolesInput {
  attributes: RolesInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
}

/** Autogenerated return type of UserSetRoles. */
export interface UserSetRolesPayload {
  __typename?: 'UserSetRolesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  user?: Maybe<User>;
}

/** Autogenerated input type of UserUpdate */
export interface UserUpdateInput {
  attributes: UserInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
}

/** Autogenerated return type of UserUpdate. */
export interface UserUpdatePayload {
  __typename?: 'UserUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  user?: Maybe<User>;
}

export enum UsersFilter {
  /** Active users */
  Active = 'ACTIVE',
  /** All users */
  All = 'ALL',
  /** Deactivated users */
  Deactivated = 'DEACTIVATED'
}

export interface ValidationError {
  __typename?: 'ValidationError';
  field: Scalars['String'];
  message: Scalars['String'];
}

export interface VarianceAnalysis {
  __typename?: 'VarianceAnalysis';
  pdfs?: Maybe<Array<VarianceAnalysisPdf>>;
  varianceAnalysisCategory?: Maybe<Array<VarianceAnalysisCategory>>;
}

export interface VarianceAnalysisAccount {
  __typename?: 'VarianceAnalysisAccount';
  account?: Maybe<Scalars['String']>;
  availableMonths?: Maybe<Array<Scalars['String']>>;
  diffsPerMonth?: Maybe<Array<VarianceAnalysisDiffsPerMonth>>;
  result?: Maybe<VarianceAnalysisResult>;
}

export interface VarianceAnalysisCategory {
  __typename?: 'VarianceAnalysisCategory';
  label?: Maybe<Scalars['String']>;
  varianceAnalysisAccount?: Maybe<Array<VarianceAnalysisAccount>>;
}

export interface VarianceAnalysisCurrent {
  __typename?: 'VarianceAnalysisCurrent';
  diff?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['String']>;
  percentChange?: Maybe<Scalars['Float']>;
  sum?: Maybe<Scalars['Float']>;
}

export interface VarianceAnalysisDiffsPerMonth {
  __typename?: 'VarianceAnalysisDiffsPerMonth';
  current?: Maybe<VarianceAnalysisCurrent>;
  previous?: Maybe<VarianceAnalysisPrevious>;
}

/** Autogenerated input type of VarianceAnalysisExportToPdf */
export interface VarianceAnalysisExportToPdfInput {
  clientId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of VarianceAnalysisExportToPdf. */
export interface VarianceAnalysisExportToPdfPayload {
  __typename?: 'VarianceAnalysisExportToPdfPayload';
  client?: Maybe<Client>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
}

export interface VarianceAnalysisPdf {
  __typename?: 'VarianceAnalysisPdf';
  fileName?: Maybe<Scalars['String']>;
  pdfFile?: Maybe<Attachment>;
  status?: Maybe<VarianceAnalysisPdfStatus>;
}

export enum VarianceAnalysisPdfStatus {
  /** Done */
  Done = 'done',
  /** Failed */
  Failed = 'failed',
  /** In progress */
  InProgress = 'in_progress',
  /** Scheduled */
  Scheduled = 'scheduled'
}

export interface VarianceAnalysisPrevious {
  __typename?: 'VarianceAnalysisPrevious';
  month?: Maybe<Scalars['String']>;
  sum?: Maybe<Scalars['Float']>;
}

export interface VarianceAnalysisResult {
  __typename?: 'VarianceAnalysisResult';
  percentChanges?: Maybe<Scalars['JSON']>;
  sum?: Maybe<Scalars['JSON']>;
  sumDiffs?: Maybe<Scalars['JSON']>;
}

export interface VcRound {
  __typename?: 'VcRound';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  names?: Maybe<Array<Scalars['String']>>;
  updatedAt: Scalars['ISO8601DateTime'];
}

export interface Vendor {
  __typename?: 'Vendor';
  accounts: Scalars['String'];
  accountsToExclude?: Maybe<Scalars['String']>;
  clients?: Maybe<Array<Client>>;
  createdAt: Scalars['ISO8601DateTime'];
  descriptions: Scalars['String'];
  descriptionsToExclude?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['ISO8601Date']>;
  error?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  keywords?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  names: Scalars['String'];
  startDate?: Maybe<Scalars['ISO8601Date']>;
  status?: Maybe<VendorReportStatuses>;
  total?: Maybe<Scalars['Boolean']>;
  transactionTypes: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
}

export interface Vendor1099Review {
  __typename?: 'Vendor1099Review';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  link: Scalars['String'];
  params: Scalars['JSON'];
  pocEmailSent?: Maybe<Scalars['ISO8601DateTime']>;
  status: Vendor1099ReviewStatuses;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
  vendorEmailSent?: Maybe<Scalars['ISO8601DateTime']>;
}

export interface Vendor1099ReviewInput {
  endDate: Scalars['ISO8601Date'];
  startDate: Scalars['ISO8601Date'];
}

export enum Vendor1099ReviewStatuses {
  /** Complete */
  Complete = 'complete',
  /** Failed */
  Failed = 'failed',
  /** Processing */
  Processing = 'processing'
}

export interface VendorAnalysis {
  __typename?: 'VendorAnalysis';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
  vendors: Array<Scalars['String']>;
  vendorsCount: Scalars['Int'];
}

/** Autogenerated input type of VendorAnalysisDelete */
export interface VendorAnalysisDeleteInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  vendorAnalysisId: Scalars['ID'];
}

/** Autogenerated return type of VendorAnalysisDelete. */
export interface VendorAnalysisDeletePayload {
  __typename?: 'VendorAnalysisDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  vendorAnalysis?: Maybe<VendorAnalysis>;
}

export interface VendorAnalysisInput {
  name: Scalars['String'];
  userId?: InputMaybe<Scalars['ID']>;
  vendors: Array<Scalars['String']>;
}

export interface VendorAnalysisPaginated {
  __typename?: 'VendorAnalysisPaginated';
  currentPage: Scalars['Int'];
  data: Array<VendorAnalysis>;
  per: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
}

/** Autogenerated input type of VendorAnalysisSave */
export interface VendorAnalysisSaveInput {
  attributes: VendorAnalysisInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  vendorAnalysisId?: InputMaybe<Scalars['ID']>;
}

/** Autogenerated return type of VendorAnalysisSave. */
export interface VendorAnalysisSavePayload {
  __typename?: 'VendorAnalysisSavePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  vendorAnalysis?: Maybe<VendorAnalysis>;
}

/** Autogenerated input type of VendorCreate */
export interface VendorCreateInput {
  attributes: VendorInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of VendorCreate. */
export interface VendorCreatePayload {
  __typename?: 'VendorCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  report?: Maybe<Vendor>;
}

export interface VendorEmailInput {
  vendorEmails: Array<Scalars['String']>;
}

export interface VendorInput {
  accountGroupsIds?: InputMaybe<Array<Scalars['Int']>>;
  accounts?: InputMaybe<Array<Scalars['String']>>;
  accountsToExclude?: InputMaybe<Array<Scalars['String']>>;
  clientIds?: InputMaybe<Array<Scalars['ID']>>;
  descriptions?: InputMaybe<Array<Scalars['String']>>;
  descriptionsToExclude?: InputMaybe<Array<Scalars['String']>>;
  endDate?: InputMaybe<Scalars['ISO8601Date']>;
  keywords?: InputMaybe<Array<Scalars['String']>>;
  names?: InputMaybe<Array<Scalars['String']>>;
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
  total?: InputMaybe<Scalars['Boolean']>;
  transactionTypes?: InputMaybe<Array<Scalars['String']>>;
}

export interface VendorReportPaginated {
  __typename?: 'VendorReportPaginated';
  currentPage: Scalars['Int'];
  data: Array<Vendor>;
  per: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
}

export enum VendorReportStatuses {
  Failed = 'FAILED',
  Generating = 'GENERATING',
  Success = 'SUCCESS'
}

/** Autogenerated input type of VendorShareReport */
export interface VendorShareReportInput {
  attributes: ShareVendorReportInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of VendorShareReport. */
export interface VendorShareReportPayload {
  __typename?: 'VendorShareReportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  success?: Maybe<Scalars['Boolean']>;
}

export interface VentureCapitalFirm {
  __typename?: 'VentureCapitalFirm';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  top?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['ISO8601DateTime'];
}

/** Autogenerated input type of VentureCapitalFirmDelete */
export interface VentureCapitalFirmDeleteInput {
  clientId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  vcFirmId?: InputMaybe<Scalars['ID']>;
}

/** Autogenerated return type of VentureCapitalFirmDelete. */
export interface VentureCapitalFirmDeletePayload {
  __typename?: 'VentureCapitalFirmDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  vcFirm?: Maybe<VentureCapitalFirm>;
}

export interface VentureCapitalFirmInput {
  rounds?: InputMaybe<Array<Scalars['String']>>;
  vcFirm: Scalars['String'];
}

/** Autogenerated input type of VentureCapitalFirmSave */
export interface VentureCapitalFirmSaveInput {
  attributes: VentureCapitalFirmInput;
  clientId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  vcFirmId?: InputMaybe<Scalars['ID']>;
}

/** Autogenerated return type of VentureCapitalFirmSave. */
export interface VentureCapitalFirmSavePayload {
  __typename?: 'VentureCapitalFirmSavePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<MutationErrors>;
  vcFirm?: Maybe<VentureCapitalFirm>;
}

export interface VersionItem {
  __typename?: 'VersionItem';
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
}
